import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Typography } from "@mui/material";
import EditText from "../../components/EditText";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetEmailTemplatesQuery,
  usePostEmailTemplateMutation,
} from "../../services/cms";
import { Loader, showError, showToast } from "../../constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageEmail = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [transactionConfirmation, setTransactionConfirmation] =
    useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");
  const [goalAchievement, setGoalAchievement] = useState<string>("");
  const [goalId, setGoalId] = useState<string>("");

  const [transferRetry, setTransferRetry] = useState<string>("");
  const [transferId, setTransferId] = useState<string>("");

  const [accountFreeze, setAccountFreeze] = useState<string>("");
  const [accountFreezeId, setAccountFreezeId] = useState<string>("");

  const [securityAlert, setSecurityAlert] = useState<string>("");
  const [securityId, setSecurityId] = useState<string>("");

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getEmailTemplates, { isLoading }] = useLazyGetEmailTemplatesQuery();
  const [postEmailTemplate] = usePostEmailTemplateMutation();
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const fetchData = async () => {
    try {
      const result = await getEmailTemplates({}).unwrap();
      if (result?.statusCode === 200 && result?.data?.data?.length) {
        result?.data?.data?.forEach((item: any) => {
          switch (Number(item.templateType)) {
            case 1:
              setTransactionConfirmation(item?.html || "");
              setTransactionId(item?._id);
              break;
            case 2:
              setGoalAchievement(item?.html || "");
              setGoalId(item?._id);
              break;
            case 3:
              setTransferRetry(item?.html || "");
              setTransferId(item?._id);
              break;
            case 4:
              setAccountFreeze(item?.html || "");
              setAccountFreezeId(item?._id);
              break;
            case 5:
              setSecurityAlert(item?.html || "");
              setSecurityId(item?._id);
              break;

            default:
              break;
          }
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const postEmail = async () => {
    let body = {
      ...(value === 0
        ? {
            templateType: 1,
            html: transactionConfirmation,
          }
        : value === 1
        ? {
            templateType: 2,
            html: goalAchievement,
          }
        : value == 2
        ? {
            templateType: 3,
            html: transferRetry,
          }
        : value === 3
        ? {
            templateType: 4,
            html: accountFreeze,
          }
        : {
            templateType: 5,
            html: securityAlert,
          }),
      appkey: Date.now(),
    };

    try {
      const response = await postEmailTemplate({
        body,
        id:
          value === 0
            ? transactionId
            : value === 1
            ? goalId
            : value === 2
            ? transferId
            : value === 3
            ? accountFreezeId
            : securityId,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        fetchData();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.data?.message || "");
    }
  };
  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Email Templates"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    fetchData();
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Email Template</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Transaction Confirmation" {...a11yProps(0)} />
              <Tab label="Goal Achievement" {...a11yProps(1)} />
              <Tab label="External Transfer Retry" {...a11yProps(2)} />
              <Tab label="Account Freeze and Return Fee" {...a11yProps(3)} />
              <Tab label="Security Alert" {...a11yProps(4)} />
            </Tabs>
            {transactionConfirmation && (
              <CustomTabPanel value={value} index={0}>
                <EditText
                  content={transactionConfirmation}
                  setContent={setTransactionConfirmation}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      onClick={() => postEmail()}
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </CustomTabPanel>
            )}
            <CustomTabPanel value={value} index={1}>
              <EditText
                content={goalAchievement}
                setContent={setGoalAchievement}
              />
              <div className="form_btn">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => postEmail()}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <EditText content={transferRetry} setContent={setTransferRetry} />
              <div className="form_btn">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => postEmail()}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <EditText content={accountFreeze} setContent={setAccountFreeze} />
              <div className="form_btn">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => postEmail()}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <EditText content={securityAlert} setContent={setSecurityAlert} />
              <div className="form_btn">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => postEmail()}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageEmail;
