import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../layout/MainContainer";

import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Permissions } from "../types/User";
import useAuth from "../hooks/useAuth";
import { Multipliers } from "../types/General";
import {
  useAddMultiplierMutation,
  useChangeMultiplierStatusMutation,
  useLazyDeleteMultiplierByIdQuery,
  useLazyGetAllMultipliersQuery,
} from "../services/roundUp";
import { Loader, showError, showToast } from "../constants";
import { Pagination, WarnModal } from "../components";
import { isNumber } from "../utils/validations";

const ManageRoundUp = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [openWarnModal, setOpenWarnModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [multipliers, setMultipliers] = useState<Multipliers[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [getMultipliers, { isLoading }] = useLazyGetAllMultipliersQuery();
  const [deleteMultiplierById, GetDeleteMultiplierData] =
    useLazyDeleteMultiplierByIdQuery();
  const [multiplierStatus, GetMultiplierStatusData] =
    useChangeMultiplierStatusMutation();
  const [addMultiplier] = useAddMultiplierMutation();

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllMultipliers = async () => {
    try {
      const response = await getMultipliers({
        limit: 10,
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMultipliers(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setMultipliers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isActive: !state,
      appkey: Date.now(),
    };
    const activeMultipliers = multipliers.filter(
      (multiplier) => multiplier.isActive
    );
    if (activeMultipliers.length > 1 || !state) {
      try {
        const response = await multiplierStatus({
          multiplier_id: id,
          body,
        }).unwrap();

        if (response?.statusCode === 200) {
          getAllMultipliers();

          if (response?.data?.isActive) {
            showToast("Multiplier has been successfully enabled");
          } else {
            showToast("Multiplier has been successfully disabled");
          }
        } else {
          showError("Please try again");
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      // Display an error message since there is only one active multiplier
      showError("At least one multiplier should be enabled.");
    }
  };

  const handleDelete = async (id: string) => {
    const activeMultipliers = multipliers.filter(
      (multiplier) => multiplier.isActive
    );
    if (activeMultipliers.length === 1 && activeMultipliers?.[0]?._id === id) {
      // Display an error message since there is only one active multiplier
      showError("At least one multiplier should be enabled.");
    } else {
      try {
        const response = await deleteMultiplierById({
          multiplier_id: id,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "");
          getAllMultipliers();
        }
      } catch (error: any) {
        console.log(error, "errror");
        showError(error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    getAllMultipliers();
  }, [page]);

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Round Up"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  // useEffect(() => {
  //   if (userData && userPermissions?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      multiplier: "",
    },
    validationSchema: Yup.object({
      multiplier: Yup.string()
        .required("This field is required")
        .max(1, "Maximum 1 digit value is allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        multiplier: Number(formik.values.multiplier),
        appkey: Date.now(),
      };
      try {
        const response = await addMultiplier({
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "");
          handleClose();
          getAllMultipliers();
        }
      } catch (error: any) {
        console.log(error);
      }
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          GetMultiplierStatusData?.isLoading ||
          GetDeleteMultiplierData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Round Up Configuration</h1>
        </div>
        {/* <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Minimum Limit
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="transactionFee"
                    variant="outlined"
                    fullWidth
                    placeholder="Minimum Limit"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    Maximum Limit
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="transactionFee"
                    variant="outlined"
                    fullWidth
                    placeholder="Maximum Limit"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Number of Breakpoints
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="transactionFee"
                    variant="outlined"
                    fullWidth
                    placeholder="Number of Break Points"
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card> */}
        <Card className="cards" sx={{ mt: 5 }}>
          <Box className="cards_header">
            <h1
              style={{ color: "#051140", fontSize: " 20px", fontWeight: 800 }}
            >
              Multipliers
            </h1>
            <Box>
              {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                <Button className="btn btn_primary" onClick={handleClickOpen}>
                  Add Multiplier
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Multiplier</TableCell>
                  <TableCell align="center"> Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {multipliers?.length ? (
                multipliers?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell align="center">
                          {`${row?.multiplier}x` || ""}
                          {/* {row?.multiplier || ""} */}
                        </TableCell>

                        <TableCell align="center">
                          <Switch
                            {...label}
                            checked={row?.isActive}
                            onClick={() =>
                              updateStatus(row?._id, row?.isActive)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() => {
                                  setOpenWarnModal(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={6}>
                    No Multipliers Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={openWarnModal}
        setOpen={setOpenWarnModal}
        handleDelete={() => handleDelete(selectedId)}
        name="multiplier"
      />
      <Pagination
        module={multipliers}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Multiplier</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                placeholder="Add Multiplier"
                autoFocus
                name="multiplier"
                type="text"
                fullWidth
                id="multiplier"
                inputProps={{ maxLength: 1 }}
                className="text_field"
                value={formik.values.multiplier}
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isNumber(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={
                  formik.touched.multiplier && formik.errors.multiplier
                }
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Add
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageRoundUp;
