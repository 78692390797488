// Staging Url ----
// export const API_URL =
//   "https://xhtnfrw8t3.execute-api.us-east-2.amazonaws.com/admin"; // development

// export const API_URL =
//   'http://192.168.1.33:3000/admin'; // Local

//  Live Url ----
export const API_URL =
  "https://0mml84wdm2.execute-api.us-west-2.amazonaws.com/admin";

  

export const END_POINTS = {
  // auth
  login: "/login",
  forgotPassword: "/forget/password",
  get_user: "/profile",
  update_profile: "/profile",
  mediaUpload: "upload",
  verifyOtp: "/forget/verify",
  resetPassword: "/reset/password",
  changePassword: "/change/password",
  logout: "/logout",

  // cms
  cms: "/cms",

  //faqs
  faq: "/faq",

  //users
  user: "/user",
  usersDeleted:"/usersDeleted",
  userCsv: "/userCsv",

  //categories
  categories: "category",

  //banner
  banner: "/banner",

  //subadmin
  subAdmin: "/subAdmin",
  subscription: "/subscription",
  multiplier: "/multiplier",

  notification: "/notification",
  dashboard: "/dashboard",

  //email templates
  emailTemplate: "emailTemplate",

  cancelSubscription: "cancelSubscription",
  settings: "/settings",
};
