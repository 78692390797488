import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

import { Loader, showError, showToast } from "../../constants";
import {
  useAddBannerMutation,
  useLazyGetBannerByIdQuery,
  useUpdateBannerMutation,
} from "../../services/banner";
import { BannerResponse, Categories } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import { isString } from "../../utils/validations";
import { useLazyGetGoalCategoriesListQuery } from "../../services/categories";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

const AddBanner = () => {
  const userData = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<Categories[]>([]);
  const [addBanner, { isLoading }] = useAddBannerMutation();
  const [getBannerById] = useLazyGetBannerByIdQuery();
  const [updateBanner, updateBannerData] = useUpdateBannerMutation();
  const [getCategoriesList] = useLazyGetGoalCategoriesListQuery();
  const [error, setError] = useState(false);
  const [image, setImage] = useState<string>("");
  const [banner, setBanner] = useState<BannerResponse>();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Marketplace"
      );
      if (idx > -1) {
        const manageAddEditPermission = permission[idx];
        if (manageAddEditPermission && !manageAddEditPermission?.isEdit) {
          navigate("/permission-denied", { replace: true });
        } else {
          setHidePermission(manageAddEditPermission);
        }
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const getCategories = async () => {
    try {
      const response = await getCategoriesList({
        type: 5,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.data || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      link: banner?.link || "",
    },
    validationSchema: Yup.object({
      link: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        link: formik.values.link,
        image: image,
        categoryId: category,
        appkey: Date.now(),
      };

      if (!image) {
        setError(true);
        return;
      }
      if (!category) {
        showError("Please select a category");
        return;
      }

      if (id) {
        try {
          const response = await updateBanner({
            banner_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-banner");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addBanner({ body }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Banner Created Successfully");
            formik.resetForm();
            navigate("/manage-banner");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const getBannerDetail = async (id: string) => {
    try {
      const response = await getBannerById({ banner_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setBanner(response?.data);
        setImage(response?.data?.image);
        setCategory(response?.data?.categoryId);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      getBannerDetail(id);
    }
  }, []);
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <>
        <Loader isLoad={isLoading || updateBannerData?.isLoading} />
        <MainContainer>
          <div className="main_loyout">
            <div className="dashboard">
              {state?.type === "edit" ? (
                <h1 className="mn_hdng">Edit Banner</h1>
              ) : (
                <h1 className="mn_hdng">Add Banner</h1>
              )}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-banner");
                }}
              >
                Back
              </Button>
            </div>
            <Card className="cards">
              <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className="custom_label">Image</Typography>
                      {image ? (
                        <div className="upload_image_preview2">
                          <CardMedia
                            component="img"
                            image={image}
                            alt="photo"
                          />
                          <CancelIcon
                            onClick={() => {
                              setImage("");
                            }}
                          />
                        </div>
                      ) : (
                        <Box className="upload_image_bnr">
                          <label htmlFor="icon-button-file">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={handleImageUpload}
                            />
                            <Button
                              component="span"
                              className="upload_image_btn"
                            >
                              <AddIcon />
                            </Button>
                          </label>
                        </Box>
                      )}

                      {error && !image ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Banner Link
                      </Typography>

                      <TextField
                        hiddenLabel
                        className="text_field"
                        type={"text"}
                        name="link"
                        variant="outlined"
                        fullWidth
                        id="link"
                        onBlur={formik.handleBlur}
                        value={formik.values.link}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={formik.touched.link && formik.errors.link}
                        placeholder="Banner Link"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Select Category
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          className="select_div"
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handleCategoryChange}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {categories?.length
                            ? categories?.map((category, index) => (
                                <MenuItem key={index} value={category?._id}>
                                  {category?.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>

                    <br />
                  </Grid>

                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      onClick={() => setError(true)}
                    >
                      Save
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </div>
        </MainContainer>
      </>
    </div>
  );
};

export default AddBanner;
