import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashboardQuery } from "../../services/dashboard";
import { Loader, showError } from "../../constants";
import { DashboardData } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [userFilter, setUserFilter] = useState<string>("MONTH");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [revenueFilter, setRevenueFilter] = useState<string>("MONTH");
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [getDashBoardData, { isLoading }] = useLazyGetDashboardQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleRevenueChange = (event: SelectChangeEvent) => {
    setRevenueFilter(event.target.value as string);
  };

  const graphCustomerReportData = {
    labels: userNames,
    datasets: [
      {
        label: "Customers ",
        data: userValues,
        borderColor: "#72cedd",
        backgroundColor: "#5ACAA7",
      },
    ],
  };
  // const graphRevenueReportsData = {
  //   labels: dashboardData?.revenue?.map((item) => item?.name),
  //   datasets: [
  //     {
  //       label: "Revenue",
  //       data: dashboardData?.revenue?.map((item) => item?.total),
  //       borderColor: "#72cedd",
  //       backgroundColor: "#72cedd",
  //     },
  //   ],
  // };
  const checkPermission = () => {
    const permission = userPermissions;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Dashboard"
      );

      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  const getDashBoard = async () => {
    try {
      const response = await getDashBoardData({
        type: userFilter,
        type2: revenueFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setDashboardData(response?.data);

        if (userFilter === "YEAR") {
          setUserNames(Object.keys(response?.data?.result));
          setUserValues(Object.values(response?.data?.result));
        } else {
          setUserNames(
            response?.data?.result?.map((item: { name: any }) => item?.name)
          );
          setUserValues(
            response?.data?.result?.map((item: { count: any }) => item?.count)
          );
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getDashBoard();
  }, [userFilter, revenueFilter]);

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Customers</h3>
              <h4 className="mn_hdng">{dashboardData?.totalCustomers || 0}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>New Customers</h3>
              <h4 className="mn_hdng">{dashboardData?.newCustomers || 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <PaidIcon className="svg_icn" />
            <div>
              <h3>Total Revenue</h3>
              <h4 className="mn_hdng">
                {dashboardData
                  ? `$${dashboardData?.totalRevenue.toFixed(2)}`
                  : ""}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <SwapHorizIcon className="svg_icn" />
            <div>
              <h3>Number of Transfers</h3>
              <h4 className="mn_hdng">{dashboardData?.noOfTransfers || 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <CurrencyExchangeIcon className="svg_icn" />
            <div>
              <h3>Overall Customers</h3>
              <h4 className="mn_hdng">
                {dashboardData?.overAllCustomers || 0}
              </h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Customers
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleUserChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="DAILY">Daily</MenuItem>
                  <MenuItem value="WEEK">Weekly</MenuItem>
                  <MenuItem value="MONTH">Monthly</MenuItem>
                  <MenuItem value="YEAR">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>

            <LineChart data={graphCustomerReportData} />
          </Item>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Revenue
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={revenueFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleRevenueChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="DAILY">Daily</MenuItem>
                  <MenuItem value="WEEK">Weekly</MenuItem>
                  <MenuItem value="MONTH">Monthly</MenuItem>
                  <MenuItem value="YEAR">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueReportsData} />
          </Item>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DashBoard;
