import { END_POINTS } from "../constants/url";
import { Faqs } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  statusCode: number;
  message: string;
  data: {
    _id: string;
    privacy: string;
    terms: string;
    agreement: string;
    email: string;
    countryCode: string;
    phone: string;
    autoDebitAuthorization: string;
    eula: string;
    tips: string[];
  }[];
};
type GetEmailResponse = {};

type PostCmsBody = {
  email: string;
  phone: string;
  countryCode: string;
  terms: string;
  privacy: string;
  agreement: string;
  autoDebitAuthorization: string;
  tips: string[];
};
type GetFaqResponse = {
  statusCode: number;
  message: string;
  data: {
    data: Faqs[];
    count: number;
  };
};
type PostFaqBody = {
  question: string;
  answer: string;
  type: number;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),

    updateCms: builder.mutation<
      GetCmsResponse,
      { body: PostCmsBody; cms_id: string }
    >({
      query: ({ body, cms_id }) => ({
        url: `${END_POINTS.cms}/${cms_id}`,
        method: "PUT",
        body,
      }),
    }),
    getFaq: builder.query<GetFaqResponse, { type: number }>({
      query: ({ type }) => ({
        url: `${END_POINTS.faq}?type=${type}`,
        method: "GET",
      }),
    }),
    addFaq: builder.mutation<GetFaqResponse, { body: PostFaqBody }>({
      query: ({ body }) => ({
        url: `${END_POINTS.faq}`,
        method: "POST",
        body,
      }),
    }),
    deleteFaq: builder.query<GetFaqResponse, { faq_id: string }>({
      query: ({ faq_id }) => ({
        url: `${END_POINTS.faq}/${faq_id}`,
        method: "DELETE",
      }),
    }),
    getEmailTemplates: builder.query<any, {}>({
      query: () => ({
        url: END_POINTS.emailTemplate,
        method: "GET",
      }),
    }),

    postEmailTemplate: builder.mutation<any, { body: any; id: string }>({
      query: ({ body, id }) => ({
        url: `${END_POINTS.emailTemplate}/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  useUpdateCmsMutation,
  useLazyGetFaqQuery,
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
  usePostEmailTemplateMutation,
  useLazyGetEmailTemplatesQuery,
} = cmsApi;
