import { END_POINTS } from "../constants/url";
import { Multipliers } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllMultipliersResponse = {
  count: number;
  data: Multipliers[];
};

type GetTokenParams = {
  limit: number;
  page: number;
};

type GetMultiplierStatusResponse = {
  isActive: boolean;
};
type MultiplierBody = {
  multiplier: number;
};

export const roundUpApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMultipliers: builder.query<
      CommonResponseType & { data: GetAllMultipliersResponse },
      GetTokenParams
    >({
      query: ({ limit, page }) => ({
        url: END_POINTS.multiplier + "?page=" + page + "&size=" + limit,
        method: "GET",
      }),
    }),

    changeMultiplierStatus: builder.mutation<
      CommonResponseType & { data: GetMultiplierStatusResponse },
      { body: { isActive: boolean }; multiplier_id: string }
    >({
      query: ({ multiplier_id, body }) => ({
        url: `${END_POINTS.multiplier}/${multiplier_id}/`,
        method: "PUT",
        body,
      }),
    }),
    addMultiplier: builder.mutation<
      CommonResponseType & { data: GetMultiplierStatusResponse },
      { body: MultiplierBody }
    >({
      query: ({ body }) => ({
        url: END_POINTS.multiplier,
        method: "POST",
        body,
      }),
    }),

    deleteMultiplierById: builder.query<
      CommonResponseType & { data: Multipliers },
      { multiplier_id: string }
    >({
      query: ({ multiplier_id }) => ({
        url: `${END_POINTS.multiplier}/${multiplier_id}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useLazyGetAllMultipliersQuery,
  useLazyDeleteMultiplierByIdQuery,
  useAddMultiplierMutation,
  useChangeMultiplierStatusMutation,
} = roundUpApi;
