import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Loader, showError, showToast } from "../../constants";
import { useFormik } from "formik";
import { Permissions } from "../../types/User";
import {
  useLazyCancelSubscriptionQuery,
  useEditSavingsLimitMutation,
  useLazyGetSavingsLimitQuery,
} from "../../services/savingsLimit";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { GetSavingsLimit } from "../../types/General";
import { isNumber } from "../../utils/validations";
import moment from "moment";

const Settings = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [getSavingsLimit, { isLoading }] = useLazyGetSavingsLimitQuery();
  const [updateSavingsLimit, UpdateSavingsLimitData] =
    useEditSavingsLimitMutation();
  const [cancelSubscription, GetCancelSubscriptionData] =
    useLazyCancelSubscriptionQuery();
  const [savingsLimitData, setSavingsLimitData] = useState<GetSavingsLimit>();
  const [transferDate, setTransferDate] = useState("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Set Points Criteria"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };
  const savingsLimit = async () => {
    try {
      const response = await getSavingsLimit({}).unwrap();
      if (response?.statusCode === 200) {
        setSavingsLimitData(response?.data?.data[0]);
        setSelectedId(response?.data?.data[0]?._id);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      transactionFee: savingsLimitData?.transactionFee || "",
      dateOfTransfer:
        moment(savingsLimitData?.dateOfTransfer).format("YYYY-MM-DD") || "",
    },
    validationSchema: Yup.object({
      transactionFee: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),
      dateOfTransfer: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let body = {
        transactionFee: Number(formik.values.transactionFee),
        dateOfTransfer: formik.values.dateOfTransfer,
        appkey: Date.now(),
      };
      console.log(body, "body");
      if (selectedId) {
        try {
          const response = await updateSavingsLimit({
            id: selectedId,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Points Criteria Saved Succesfully");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });
  const subscriptionFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fieldName: "",
    },
    validationSchema: Yup.object({
      fieldName: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required"),
    }),
    onSubmit: async (values) => {
      subscriptionFormik.setSubmitting(true);

      // let body = {
      //   fieldName: subscriptionFormik.values.fieldName,
      //   appkey: Date.now(),
      // };
      // console.log(body, "body");

      try {
        const response = await cancelSubscription({
          fieldName: subscriptionFormik.values.fieldName,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "Subscription Cancelled Successfully");
          subscriptionFormik.resetForm();
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(() => {
    savingsLimit();
  }, []);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          UpdateSavingsLimitData?.isLoading ||
          GetCancelSubscriptionData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography className="sub_headings">
                    Manage Transaction Fee
                  </Typography>
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    Transaction Fee
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="transactionFee"
                    variant="outlined"
                    fullWidth
                    placeholder="Transaction Fee"
                    id="transactionFee"
                    inputProps={{ maxLength: 3 }}
                    className="text_field"
                    value={formik.values.transactionFee}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.transactionFee &&
                      formik.errors.transactionFee
                    }
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4} />
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography className="sub_headings">
                    Manage Transfer Savings
                  </Typography>
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    Date of Transfer
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Date of Transfer"
                    fullWidth
                    name="dateOfTransfer"
                    variant="outlined"
                    type={"date"}
                    id="dateOfTransfer"
                    onBlur={formik.handleBlur}
                    value={formik.values.dateOfTransfer}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.dateOfTransfer &&
                      formik.errors.dateOfTransfer
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="sub_headings">
                    Enable/Disable Apis
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  sx={{ paddingTop: "10px" }}
                >
                  <Box className="switch_flex">
                    <Typography className="custom_label">ACHQ</Typography>
                    <Switch {...label} defaultChecked size="small" />
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  sx={{ paddingTop: "10px" }}
                >
                  <Box className="switch_flex">
                    <Typography className="custom_label">Plaid</Typography>
                    <Switch {...label} defaultChecked size="small" />
                  </Box>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
        <Card className="cards" style={{ marginTop: "20px" }}>
          <form onSubmit={subscriptionFormik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  // sx={{ paddingBottom: "10px" }}
                >
                  <Typography className="sub_headings">
                    Cancel Recurring
                  </Typography>
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    Enter subscription id
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="fieldName"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter subscription id"
                    id="fieldName"
                    inputProps={{ maxLength: 40 }}
                    className="text_field"
                    value={subscriptionFormik.values.fieldName}
                    onBlur={subscriptionFormik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        subscriptionFormik.handleChange(val);
                      }
                    }}
                    helperText={
                      subscriptionFormik.touched.fieldName &&
                      subscriptionFormik.errors.fieldName
                    }
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Submit
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Settings;
