import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { GetSubadminResponse } from "../../types/General";
import {
  useLazyChangeSubAdminStatusQuery,
  useLazyDeleteSubAdminByIdQuery,
  useLazyGetAllSubAdminsQuery,
} from "../../services/subadmin";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const [subadminList, setSubadminList] = useState<GetSubadminResponse[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getSubadminList, { isLoading }] = useLazyGetAllSubAdminsQuery();
  const [SubAdminStatus, GetSubadminStatusData] =
    useLazyChangeSubAdminStatusQuery();
  const [deleteSubadminById, deleteSubadminByIdData] =
    useLazyDeleteSubAdminByIdQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getSubadmins = async () => {
    try {
      const response = await getSubadminList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubadminList(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubadminList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isBlocked: !state,
      appkey: Date.now(),
    };
    try {
      const response = await SubAdminStatus({
        sub_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getSubadmins();
        if (response?.data?.isBlocked) {
          showToast("Sub-admin has been successfully disabled");
        } else {
          showToast("Sub-admin has been successfully enabled");
        }
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubadminById({ subadmin_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getSubadmins();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSubadmins();
  }, [debouncedSearchTerm, page]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          deleteSubadminByIdData?.isLoading ||
          GetSubadminStatusData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                Add Sub-Admin
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {subadminList?.length ? (
                subadminList?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>{`${item?.firstName || "-"} ${
                          item?.lastName || ""
                        }`}</TableCell>
                        <TableCell>{item?.email || ""}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {item?.phone
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                              item?.countryCode
                            : null}{" "}
                          {item?.phone || "---"}
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            onChange={() =>
                              updateStatus(item?._id, item?.isBlocked)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-subAdmin/edit/${item?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={10}>
                    No Subadmin Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={subadminList}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="subadmin"
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
