import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useChangeCategoryStatusMutation,
  useLazyDeleteCategoryByIdQuery,
  useLazyGetGoalCategoriesListQuery,
  useLazyGetGoalSubcategoriesListQuery,
} from "../../services/categories";
import { Categories, SubCategoriesType } from "../../types/General";
import {
  Loader,
  STORAGE_KEYS,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import SearchBar from "../../components/SearchBar";
import { Pagination, WarnModal } from "../../components";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const SubCategories = () => {
  const userData = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [open, setOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<SubCategoriesType[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const [getCategoriesList, { isLoading }] =
    useLazyGetGoalSubcategoriesListQuery();
  const [categoryStatus, GetChangeCategoryData] =
    useChangeCategoryStatusMutation();
  const [deleteCategoryById, getDeleteCategoryData] =
    useLazyDeleteCategoryByIdQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getCategories = async () => {
    try {
      const response = await getCategoriesList({
        parent: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategoryById({ category_id: id }).unwrap();
      if (response?.statusCode === 200) {
        getCategories();
        showToast(response?.message || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isActive: !state,
      appkey: Date.now(),
    };
    try {
      const response = await categoryStatus({
        category_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getCategories();
        if (response?.data?.isActive) {
          showToast("Category has been successfully enabled");
        } else {
          showToast("Category has been successfully disabled");
        }
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Categories"
      );
      if (idx > -1) {
        const manageViewPermission = permission[idx];
        if (manageViewPermission && !manageViewPermission?.isView) {
          navigate("/permission-denied", { replace: true });
        } else {
          setHidePermission(manageViewPermission);
        }
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getCategories();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          GetChangeCategoryData?.isLoading ||
          getDeleteCategoryData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Sub-Categories</h1>
        </div>
        <Card className="cards">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 0 }} className="mn_hdng">
              {categories?.[0]?.parent?.name || ""}
            </h4>
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/manage-categories", { state: "GOAL" })}
            >
              Back
            </Button>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell>Sub-Category Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {categories?.length ? (
                categories?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">{1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          <figure className="user_img">
                            <img
                              src={
                                item?.image
                                  ? item?.image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{item?.name || "-"}</TableCell>

                        <TableCell align="center">
                          {" "}
                          <Switch
                            checked={item?.isActive}
                            onClick={() =>
                              updateStatus(item?._id, item?.isActive)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => {
                                navigate(
                                  `/manage-categories/edit/${item?._id}`,
                                  {
                                    state: "GOAL",
                                  }
                                );
                                setToStorage(STORAGE_KEYS.categoryType, "GOAL");
                              }}
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    // sx={{ color: "white" }}
                  >
                    No Sub-categories found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={categories}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="category"
      />
    </MainContainer>
  );
};

export default SubCategories;
