import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import {
  useChangeSubscriptionStatusMutation,
  useEditSubscriptionSettingsMutation,
  useLazyDeleteSubscriptionByIdQuery,
  useLazyGetAllSubscriptionsQuery,
  useLazyGetSubscriptionSettingsQuery,
} from "../../services/subscription";
import { GetSavingsLimit, Subscriptions } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";

const ManageSubscription = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [settings, setSettings] = useState<GetSavingsLimit>();
  const [getSubscriptionSettings] = useLazyGetSubscriptionSettingsQuery();
  const [updateSubscriptionSettings] = useEditSubscriptionSettingsMutation();

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [subscriptionResponse, setSubscriptionResponse] = useState<
    Subscriptions[]
  >([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] =
    useState<string>("");
  const [isAndroid, setIsAndroid] = useState<boolean>(
    settings?.isAndroid || false
  );
  const [isIos, setIsIos] = useState<boolean>(settings?.isIos || false);
  const [isAutoDebitAndroid, setIsAutoDebitAndroid] = useState<boolean>(
    settings?.isDebitAndroid || false
  );

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [isAutoDebitIos, setIsAutoDebitIos] = useState<boolean>(
    settings?.isDebitIos || false
  );
  const [isSkipButton, setIsSkipButton] = useState<boolean>(
    settings?.isSkipButton || false
  );

  const [selectedId, setSelectedId] = useState<string>("");
  const [getSubscription, { isLoading }] = useLazyGetAllSubscriptionsQuery();
  const [deleteSubscriptionById, GetDeleteSubscriptionData] =
    useLazyDeleteSubscriptionByIdQuery();
  const [subscriptionStatus, GetSubsriptionStatusData] =
    useChangeSubscriptionStatusMutation();

  const SubscriptionSettings = async () => {
    try {
      const response = await getSubscriptionSettings({}).unwrap();
      if (response?.statusCode === 200) {
        setSettings(response?.data?.data[0]);
        setSelectedSubscriptionId(response?.data?.data[0]?._id);
        setIsAndroid(response?.data?.data[0]?.isAndroid || false);
        setIsIos(response?.data?.data[0]?.isIos || false);
        setIsAutoDebitIos(response?.data?.data[0]?.isDebitIos || false);
        setIsAutoDebitAndroid(response?.data?.data[0]?.isDebitAndroid || false);
        setIsSkipButton(response?.data?.data[0]?.isSkipButton || false);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const editAndroidSubscriptionSettings = async (androidState: boolean) => {
    let body = {
      isAndroid: androidState,
      isIos: isIos,
      appkey: Date.now(),
    };

    if (selectedSubscriptionId) {
      try {
        const response = await updateSubscriptionSettings({
          id: selectedSubscriptionId,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          SubscriptionSettings();
          showToast("Status updated successfully");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    }
  };
  const editIosSubscriptionSettings = async (iosState: boolean) => {
    let body = {
      isAndroid: isAndroid,
      isIos: iosState,
      appkey: Date.now(),
    };

    if (selectedSubscriptionId) {
      try {
        const response = await updateSubscriptionSettings({
          id: selectedSubscriptionId,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          SubscriptionSettings();
          showToast("Status updated successfully");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    }
  };

  const editSkipButtonSettings = async (skipButtonState: boolean) => {
    let body = {
      isAndroid: isAndroid,
      isIos: isIos,
      isSkipButton: skipButtonState,
      appkey: Date.now(),
    };

    if (selectedSubscriptionId) {
      try {
        const response = await updateSubscriptionSettings({
          id: selectedSubscriptionId,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          SubscriptionSettings();
          showToast("Skip button status updated successfully");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    }
  };
  const editAndroidAutoDebitSettings = async (androidState: boolean) => {
    let body = {
      isDebitAndroid: androidState,
      isDebitIos: isAutoDebitIos,
      appkey: Date.now(),
    };

    if (selectedSubscriptionId) {
      try {
        const response = await updateSubscriptionSettings({
          id: selectedSubscriptionId,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          SubscriptionSettings();
          showToast("Status updated successfully");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    }
  };
  const editIosAutoDebitSettings = async (iosState: boolean) => {
    let body = {
      isDebitAndroid: isAutoDebitAndroid,
      isDebitIos: iosState,
      appkey: Date.now(),
    };
    console.log(body, "body");

    if (selectedSubscriptionId) {
      try {
        const response = await updateSubscriptionSettings({
          id: selectedSubscriptionId,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          SubscriptionSettings();
          showToast("Status updated successfully");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    }
  };
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];
  console.log(userPermissions, "userPermissions");

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllSubscriptions = async () => {
    try {
      const response = await getSubscription({
        limit: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubscriptionResponse(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubscriptionResponse([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isActive: !state,
      appkey: Date.now(),
    };
    try {
      const response = await subscriptionStatus({
        subscription_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getAllSubscriptions();
        if (response?.data?.isActive) {
          showToast("Subscription plan has been successfully enabled");
        } else {
          showToast("Subscription plan has been successfully disabled ");
        }
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubscriptionById({
        subscription_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getAllSubscriptions();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllSubscriptions();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    SubscriptionSettings();
  }, []);

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Subscription Plans"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      }
    } else {
      navigate("/permission-denied", { replace: true });
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          GetSubsriptionStatusData?.isLoading ||
          GetDeleteSubscriptionData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Subscription Plans</h1>
        </div>
        <Card className="cards" style={{ marginBottom: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Typography className="sub_headings">
                  Enable/Disable Subscription Plans
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box className="switch_flex">
                  <Typography className="custom_label">Android</Typography>
                  <Switch
                    {...label}
                    checked={isAndroid}
                    onChange={() => {
                      editAndroidSubscriptionSettings(!isAndroid);
                      setIsAndroid(!isAndroid);
                    }}
                    size="small"
                    // onChange={() => editAndroidSubscriptionSettings(isAndroid)}
                  />
                </Box>
                <Box className="switch_flex">
                  <Typography className="custom_label"> iOS</Typography>
                  <Switch
                    {...label}
                    size="small"
                    checked={isIos}
                    onChange={() => {
                      editIosSubscriptionSettings(!isIos);
                      setIsIos(!isIos);
                    }}
                  />
                </Box>
                <Box className="switch_flex">
                  <Typography className="custom_label">Skip Button</Typography>
                  <Switch
                    {...label}
                    size="small"
                    checked={isSkipButton}
                    onChange={() => {
                      editSkipButtonSettings(!isSkipButton);
                      setIsSkipButton(!isSkipButton);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Typography className="sub_headings">
                  Enable/Disable Auto Debit Authorization
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box className="switch_flex">
                  <Typography className="custom_label">Android</Typography>
                  <Switch
                    {...label}
                    checked={isAutoDebitAndroid}
                    onChange={() => {
                      editAndroidAutoDebitSettings(!isAutoDebitAndroid);
                      setIsAutoDebitAndroid(!isAutoDebitAndroid);
                    }}
                    size="small"
                    // onChange={() => editAndroidSubscriptionSettings(isAndroid)}
                  />
                </Box>
                <Box className="switch_flex">
                  <Typography className="custom_label"> iOS</Typography>
                  <Switch
                    {...label}
                    size="small"
                    checked={isAutoDebitIos}
                    onChange={() => {
                      editIosAutoDebitSettings(!isAutoDebitIos);
                      setIsAutoDebitIos(!isAutoDebitIos);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-subscription/add")}
                  >
                    Add Plan
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Plan Name</TableCell>
                    <TableCell>Plan Validity (months)</TableCell>
                    <TableCell>Plan Price</TableCell>
                    <TableCell>Number of Bank Accounts </TableCell>

                    <TableCell> Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {subscriptionResponse?.length ? (
                  subscriptionResponse?.map((row, i) => {
                    return (
                      <TableBody key={row?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {" "}
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.name || ""}</TableCell>
                          <TableCell align="center">
                            {row?.validity || ""}
                          </TableCell>
                          <TableCell align="center">
                            ${row?.price || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.noOfAccounts || ""}{" "}
                          </TableCell>

                          <TableCell>
                            <Switch
                              {...label}
                              checked={row?.isActive}
                              onClick={() =>
                                updateStatus(row?._id, row?.isActive)
                              }
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              {hidePermission?.isEdit ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-subscription/edit/${row?._id}`,
                                      {
                                        state: { type: "edit" },
                                      }
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              ) : null}
                              {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                ) : (
                  <TableBody>
                    <TableCell align="center" colSpan={6}>
                      No Subscription Plan Found
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="subscription plan"
      />
      <Pagination
        module={subscriptionResponse}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageSubscription;
