import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { Dispatch, SetStateAction, useState } from "react";
import { Faqs } from "../types/General";
import { Permissions, User } from "../types/User";

type props = {
  faqsData: Faqs[];
  question: string;
  answer: string;
  setAnswer: Dispatch<SetStateAction<string>>;
  setQuestion: Dispatch<SetStateAction<string>>;
  hidePermission: Permissions | null | undefined;
  userData: User | null;
  handleDelete: (id: string) => void;
  handleAddFaq: () => void;
};
const MainFaq = ({
  faqsData,
  handleDelete,
  question,
  answer,
  setAnswer,
  setQuestion,
  handleAddFaq,
  hidePermission,
  userData,
}: props) => {
  return (
    <Grid container spacing={2}>
      {faqsData?.length
        ? faqsData?.map((item, index) => {
            return (
              <Grid key={index} item xs={12}>
                <Box className="faq_box">
                  <FormControl sx={{ mb: 2, width: "100%" }}>
                    <Typography className="custom_label">Question</Typography>
                    <TextField
                      hiddenLabel
                      multiline
                      minRows={1}
                      maxRows={4}
                      type={"text"}
                      name="name"
                      variant="outlined"
                      value={item?.question}
                      placeholder="Question"
                      disabled
                    ></TextField>
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Typography className="custom_label">Answer</Typography>
                    <TextField
                      hiddenLabel
                      multiline
                      minRows={1}
                      maxRows={4}
                      type={"text"}
                      name="name"
                      variant="outlined"
                      value={item?.answer}
                      placeholder="Answer"
                      disabled
                    ></TextField>
                  </FormControl>
                  <Box className="faq_btn">
                    {hidePermission?.isDelete || userData?.role === "ADMIN" ? (
                      <Button
                        variant="contained"
                        className="btn btn_primary sm"
                        onClick={() => handleDelete(item?._id)}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            );
          })
        : null}

      {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
        <Grid item xs={12}>
          <Box className="faq_box">
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Question</Typography>
              <TextField
                hiddenLabel
                multiline
                minRows={1}
                maxRows={4}
                type={"text"}
                name="name"
                variant="outlined"
                value={question}
                onChange={(val) => setQuestion(val.target.value)}
                placeholder="Question"
              ></TextField>
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Answer</Typography>
              <TextField
                hiddenLabel
                multiline
                minRows={1}
                maxRows={4}
                type={"text"}
                name="name"
                variant="outlined"
                value={answer}
                onChange={(val) => setAnswer(val.target.value)}
                placeholder="Answer"
              ></TextField>
            </FormControl>
            <Box className="faq_btn">
              <Button
                variant="contained"
                disabled={!question || !answer}
                className="btn btn_primary sm"
                onClick={handleAddFaq}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default MainFaq;
