import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  useEditSavingsLimitMutation,
  useLazyGetSavingsLimitQuery,
} from "../../services/savingsLimit";
import { Loader, showError, showToast } from "../../constants";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber } from "../../utils/validations";
import { GetSavingsLimit } from "../../types/General";

const ManageSavingsLimit = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [getSavingsLimit, { isLoading }] = useLazyGetSavingsLimitQuery();
  const [updateSavingsLimit, UpdateSavingsLimitData] =
    useEditSavingsLimitMutation();
  const [savingsLimitData, setSavingsLimitData] = useState<GetSavingsLimit>();
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Savings Limit"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  const savingsLimit = async () => {
    try {
      const response = await getSavingsLimit({}).unwrap();
      if (response?.statusCode === 200) {
        setSavingsLimitData(response?.data?.data[0]);
        setSelectedId(response?.data?.data[0]?._id);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    savingsLimit();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      minLimitOverall:
        savingsLimitData?.overallSavingsLimit?.minimumGoalAmountLimit || "",
      maxLimitOverall:
        savingsLimitData?.overallSavingsLimit?.maximumGoalAmountLimit || "",
      breakPointsOverall:
        savingsLimitData?.overallSavingsLimit?.noOfBreakPoints || "",
      minLimitMonthly:
        savingsLimitData?.monthlySavingsLimit?.minimumGoalAmountLimit || "",
      maxLimitMonthly:
        savingsLimitData?.monthlySavingsLimit?.maximumGoalAmountLimit || "",
      breakPointsMonthly:
        savingsLimitData?.monthlySavingsLimit?.noOfBreakPoints || "",
    },
    validationSchema: Yup.object({
      minLimitOverall: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),

      maxLimitOverall: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),
      breakPointsOverall: Yup.string()
        .required("This field is required")
        .max(4, "Maximum 4 digits are allowed"),
      minLimitMonthly: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),

      maxLimitMonthly: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),

      breakPointsMonthly: Yup.string()
        .required("This field is required")
        .max(4, "Maximum 4 digits are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let body = {
        overallSavingsLimit: {
          minimumGoalAmountLimit: Number(formik.values.minLimitOverall),
          maximumGoalAmountLimit: Number(formik.values.maxLimitOverall),
          noOfBreakPoints: Number(formik.values.breakPointsOverall),
        },
        monthlySavingsLimit: {
          minimumGoalAmountLimit: Number(formik.values.minLimitMonthly),
          maximumGoalAmountLimit: Number(formik.values.maxLimitMonthly),
          noOfBreakPoints: Number(formik.values.breakPointsMonthly),
        },
        appkey: Date.now(),
      };
      console.log(body, "body");
      if (selectedId) {
        try {
          const response = await updateSavingsLimit({
            id: selectedId,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "Savings Limit Updated Succesfully");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || UpdateSavingsLimitData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Savings Limit</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="sub_headings">
                    Set Overall Savings Limit
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Minimum Limit of Goal Amount
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="minLimitOverall"
                    variant="outlined"
                    fullWidth
                    placeholder="Minimum Limit"
                    id="minLimitOverall"
                    inputProps={{ maxLength: 8 }}
                    className="text_field"
                    value={formik.values.minLimitOverall}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.minLimitOverall &&
                      formik.errors.minLimitOverall
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    Maximum Limit of Goal Amount
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="maxLimitOverall"
                    variant="outlined"
                    fullWidth
                    placeholder="Maximum Limit"
                    id="maxLimitOverall"
                    inputProps={{ maxLength: 8 }}
                    className="text_field"
                    value={formik.values.maxLimitOverall}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.maxLimitOverall &&
                      formik.errors.maxLimitOverall
                    }
                  />
                  {/* {error &&
                  formik.values.maxLimitOverall &&
                  formik.values.maxLimitOverall <=
                    formik.values.minLimitOverall ? (
                    <h6 className="err_msg">
                      Maximum limit of goal amount cannot be less than minimum
                      limit of goal amount{" "}
                    </h6>
                  ) : (
                    ""
                  )} */}
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Number of Breakpoints
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="breakPointsOverall"
                    variant="outlined"
                    fullWidth
                    placeholder="Number of Break Points"
                    id="breakPointsOverall"
                    inputProps={{ maxLength: 4 }}
                    className="text_field"
                    value={formik.values.breakPointsOverall}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.breakPointsOverall &&
                      formik.errors.breakPointsOverall
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="sub_headings">
                    Set Monthly Savings Limit
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Minimum Limit of Goal Amount
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="minLimitMonthly"
                    variant="outlined"
                    fullWidth
                    placeholder="Minimum Limit"
                    id="minLimitMonthly"
                    inputProps={{ maxLength: 8 }}
                    className="text_field"
                    value={formik.values.minLimitMonthly}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.minLimitMonthly &&
                      formik.errors.minLimitMonthly
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    Maximum Limit of Goal Amount
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="maxLimitMonthly"
                    variant="outlined"
                    fullWidth
                    placeholder="Maximum Limit"
                    id="maxLimitMonthly"
                    inputProps={{ maxLength: 8 }}
                    className="text_field"
                    value={formik.values.maxLimitMonthly}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.maxLimitMonthly &&
                      formik.errors.maxLimitMonthly
                    }
                  />
                  {/* {error &&
                  formik.values.maxLimitMonthly &&
                  formik.values.maxLimitMonthly <=
                    formik.values.minLimitMonthly ? (
                    <h6 className="err_msg">
                      Maximum limit of goal amount cannot be less than minimum
                      limit of goal amount{" "}
                    </h6>
                  ) : (
                    ""
                  )} */}
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Number of Breakpoints
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="breakPointsMonthly"
                    variant="outlined"
                    fullWidth
                    placeholder="Number of Break Points"
                    id="breakPointsMonthly"
                    inputProps={{ maxLength: 4 }}
                    className="text_field"
                    value={formik.values.breakPointsMonthly}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.breakPointsMonthly &&
                      formik.errors.breakPointsMonthly
                    }
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    // onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageSavingsLimit;
