import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditUserByIdMutation,
  useLazyGetUserByIdQuery,
} from "../../../services/customers";
import { AllUser } from "../../../types/General";
import { useEffect, useState } from "react";
import { Loader, showError, showToast } from "../../../constants";
import { useFormik } from "formik";
import { isString } from "../../../utils/validations";

const UsersForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<AllUser>();
  const [phoneCode, setPhoneCode] = useState("+91");

  const [editUser] = useEditUserByIdMutation();
  const [getUserById, getUserByIdData] = useLazyGetUserByIdQuery();

  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        user_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data);
        setPhoneCode(response?.data?.countryCode || "+91");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      phone: userData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string()
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        appkey: Date.now(),
      };
      try {
        const response = await editUser({ body, user_id: `${id}` }).unwrap();
        showToast(response?.message || "User Updated Successfully");
        navigate("/manageusers");
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  useEffect(() => {
    if (id) {
      getUserDetail(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={getUserByIdData?.isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng"> Edit User Profile</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manageUsers");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">First Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      placeholder="FirstName"
                      id="firstName"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      inputProps={{ maxLength: 30 }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Last Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      placeholder="Lastname"
                      id="lastName"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default UsersForm;
