import { END_POINTS } from "../constants/url";
import { NotificationResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetNotificationsResponse = {
  data: {
    count: number;
    data: NotificationResponse[];
  };
  statusCode: number;
  message: string;
};

type PostNotificationBody = {
  title: string;
  message?: string;
  users?: string[];
};

export const notificationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      GetNotificationsResponse,
      { limit: number; page: number; query: string }
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.notification +
          "?page=" +
          page +
          "&size=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getNotificationById: builder.query<
      { data: NotificationResponse; statusCode: number; message: string },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "GET",
      }),
    }),

    addNotification: builder.mutation<
      GetNotificationsResponse,
      { body: PostNotificationBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.notification}`,
        method: "POST",
        body,
      }),
    }),

    deleteNotification: builder.query<
      GetNotificationsResponse,
      { notification_id: string }
    >({
      query: ({ notification_id }) => ({
        url: `${END_POINTS.notification}/${notification_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetNotificationsQuery,
  useLazyGetNotificationByIdQuery,
  useLazyDeleteNotificationQuery,
  useAddNotificationMutation,
} = notificationApi;
