/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { AllUser } from "../../types/General";
import {
  useChangeUserStatusMutation,
  useLazyDeleteUserIdQuery,
  useLazyGetAllUsersQuery,
  useLazyGetUserCSVQuery,
} from "../../services/customers";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import moment from "moment";

const ManageUsers = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getAllUsersList, { isLoading }] = useLazyGetAllUsersQuery();
  const [getUserCsvMethod] = useLazyGetUserCSVQuery();
  const [deleteUserById, GetDeleteUserData] = useLazyDeleteUserIdQuery();
  const [userStatus, GetUserStatusData] = useChangeUserStatusMutation();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Customers"
      );

      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getUsers = async () => {
    try {
      const response = await getAllUsersList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllUsers(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUserById({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isBlocked: !state,
      appkey: Date.now(),
    };
    try {
      const response = await userStatus({
        user_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getUsers();
        if (response?.data?.isBlocked) {
          showToast("User has been successfully disabled");
        } else {
          showToast("User has been successfully enabled");
        }
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.fileUrl || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  useEffect(() => {
    getUsers();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          GetUserStatusData?.isLoading ||
          GetDeleteUserData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Customers</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              placeholder="Search by Name"
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Name</TableCell>
                  <TableCell>Phone No.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell align="center">Account Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {allUsers?.length ? (
                allUsers?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>
                          {" "}
                          {`${item?.firstName} ${item?.lastName}` || ""}
                        </TableCell>

                        <TableCell>
                          {" "}
                          {item?.countryCode
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                              item?.countryCode
                            : null}{" "}
                          {item?.phone || "-"}
                        </TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell>
                          {moment(item?.createdAt).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!item?.isBlocked}
                            onChange={() =>
                              updateStatus(item?._id, item?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manageusers/userdetails/${item?._id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {hidePermission?.isEdit ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manageusers/usersform/${item?._id}`
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : null}
                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Customers Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={allUsers}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="customer"
      />
    </MainContainer>
  );
};

export default ManageUsers;
