import { END_POINTS } from "../constants/url";
import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: DashboardData },
      { type: string; type2: string }
    >({
      query: ({ type, type2 }) => ({
        url: `${END_POINTS.dashboard}?type=${type}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetDashboardQuery } = dashboardApi;
