import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useAddCategoryMutation,
  useEditCategoryByIdMutation,
  useLazyGetAllCategoriesQuery,
  useLazyGetCategoryByIdQuery,
  useLazyGetGoalCategoriesListQuery,
} from "../../services/categories";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  showError,
  showToast,
} from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import { isString } from "../../utils/validations";
import { Categories, CategoryByIdResponse } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const AddCategories = () => {
  const userData = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const categoryType = getFromStorage(STORAGE_KEYS?.categoryType);

  const [category, setCategory] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [categories, setCategories] = useState<Categories[]>([]);
  const [getCategoriesList] = useLazyGetGoalCategoriesListQuery();
  const [getCategoryById, getCategoryByIdData] = useLazyGetCategoryByIdQuery();
  const [updateCategory, getUpdateCategoryData] = useEditCategoryByIdMutation();
  const [categoryData, setCategoryData] = useState<CategoryByIdResponse>();

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const [addCategory, { isLoading }] = useAddCategoryMutation();

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const getCategories = async () => {
    try {
      const response = await getCategoriesList({
        type: 2,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.data || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getCategoryDetail = async (id: string) => {
    try {
      const response = await getCategoryById({
        category_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data);
        setImage(response?.data?.image || "");
        setCategory(response?.data?.parent || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: categoryData?.name || "",
      subCategoryName: categoryData?.name || "",
    },
    validationSchema: Yup.object({
      name:
        categoryType === "GOAL"
          ? Yup.string().notRequired()
          : Yup.string()
              .required("This field is required")
              .min(2, "Minimum 2 characters are required")
              .max(30, "Maximum 30 characters are allowed"),
      subCategoryName:
        categoryType === "GOAL"
          ? Yup.string()
              .required("This field is required!")
              .min(2, "Minimum 2 characters are required")
              .max(30, "Maximum 30 characters are allowed")
          : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        name: formik.values.name,
        image: image,
        type: state.tab === "BUDGET" ? 1 : 5,
        appkey: Date.now(),
      };
      console.log(body);

      const goal_body = {
        name: formik.values.subCategoryName,
        image: image,
        type: 2,
        parent: category,
        appkey: Date.now(),
      };
      if (!image) {
        setError(true);
        return;
      }

      if (categoryType === "GOAL") {
        if (!category) {
          showError("Please select a category");
          return;
        }

        if (id) {
          try {
            const response = await updateCategory({
              category_id: `${id}`,
              body: goal_body,
            }).unwrap();
            if (response?.statusCode === 200) {
              showToast(response?.message || "Category Updated Successfully");
              navigate("/manage-categories", { state: "GOAL" });
            }
          } catch (error: any) {
            console.log(error);
          }
        } else {
          try {
            console.log("goal_add");
            const response = await addCategory({ body: goal_body }).unwrap();
            if (response?.statusCode === 200) {
              showToast(response?.message || "Category Added Successfully");
              navigate("/manage-categories", { state: "GOAL" });
            }
          } catch (error: any) {
            console.log(error);
          }
        }
      } else {
        if (id) {
          try {
            console.log("budget_edit");
            const response = await updateCategory({
              category_id: `${id}`,
              body,
            }).unwrap();
            if (response?.statusCode === 200) {
              showToast(response?.message || "Category Updated Successfully");
              navigate("/manage-categories");
            }
          } catch (error: any) {
            console.log(error);
          }
        } else {
          try {
            console.log("budget_add");
            const response = await addCategory({ body }).unwrap();
            if (response?.statusCode === 200) {
              showToast(response?.message || "Category Added Successfully");
              navigate("/manage-categories");
            }
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    },
  });
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Categories"
      );
      if (idx > -1) {
        const manageAddEditPermission = permission[idx];
        if (manageAddEditPermission && !manageAddEditPermission?.isEdit) {
          navigate("/permission-denied", { replace: true });
        } else {
          setHidePermission(manageAddEditPermission);
        }
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      getCategoryDetail(id);
    }
  }, []);
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={getCategoryByIdData?.isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            {id ? (
              <h1 className="mn_hdng">Edit Category</h1>
            ) : (
              <h1 className="mn_hdng">Add Category</h1>
            )}
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories", {
                  state: categoryType === "GOAL" ? "GOAL" : null,
                });
                removeFromStorage(STORAGE_KEYS?.categoryType);
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {!id && categoryType === "GOAL" ? (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Select Category
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          className="select_div"
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handleCategoryChange}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {categories?.length
                            ? categories?.map((category, index) => (
                                <MenuItem key={index} value={category?._id}>
                                  {category?.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {categoryType === "GOAL" ? (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Sub-category Name
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="subCategoryName"
                        variant="outlined"
                        fullWidth
                        placeholder="Sub-category name"
                        inputProps={{ maxLength: 30 }}
                        id="subCategoryName"
                        onBlur={formik.handleBlur}
                        value={formik.values.subCategoryName}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.subCategoryName &&
                          formik.errors.subCategoryName
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Category Name
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="name"
                        variant="outlined"
                        fullWidth
                        placeholder="Category Name"
                        inputProps={{ maxLength: 30 }}
                        id="name"
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                  )}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
