import { END_POINTS } from "../constants/url";
import { AllUser } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllUsersResponse = {
  count: number;
  data: AllUser[];
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
};
type EditUserById = {
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
};

type GetUserStatusResponse = {
  isBlocked: boolean;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<
      CommonResponseType & { data: GetAllUsersResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.user +
          "?page=" +
          page +
          "&size=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getUsers: builder.query<
      CommonResponseType & { data: GetAllUsersResponse },
      {}
    >({
      query: () => ({
        url: END_POINTS.user,
        method: "GET",
      }),
    }),
    usersDeleted: builder.query<
      CommonResponseType & { data: GetAllUsersResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.usersDeleted +
          "?page=" +
          page +
          "&size=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getUserCSV: builder.query<CommonResponseType & { fileUrl: string }, {}>({
      query: () => ({
        url: END_POINTS.userCsv,
        method: "GET",
      }),
    }),
    changeUserStatus: builder.mutation<
      CommonResponseType & { data: GetUserStatusResponse },
      { body: { isBlocked: boolean }; user_id: string }
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.user}/${user_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getUserById: builder.query<
      CommonResponseType & { data: AllUser },
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.user}/${user_id}`,
        method: "GET",
      }),
    }),

    editUserById: builder.mutation<
      CommonResponseType & { data: AllUser },
      { user_id: string; body: EditUserById }
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.user}/${user_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteUserId: builder.query<
      CommonResponseType & { data: AllUser },
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.user}/${user_id}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useLazyGetAllUsersQuery,
  useLazyGetUsersQuery,
  useLazyUsersDeletedQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUserCSVQuery,
  useEditUserByIdMutation,
  useChangeUserStatusMutation,
  useLazyDeleteUserIdQuery,
} = mainApi;
