import React, { useEffect, useState } from "react";
import { useLazyGetNotificationByIdQuery } from "../../services/notification";
import { NotificationResponse } from "../../types/General";
import { Loader, showError } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";

const NotificationDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notificationData, setNotificationData] =
    useState<NotificationResponse>();

  const [getNotification, { isLoading }] = useLazyGetNotificationByIdQuery();

  const getNotificationDetail = async (id: string) => {
    try {
      const response = await getNotification({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setNotificationData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) {
      getNotificationDetail(id);
    }
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Notification Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-notifications");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Notification Title</Typography>
                      <Typography component="p">
                        {notificationData?.title || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Date</Typography>
                      <Typography component="p">
                        {moment(notificationData?.createdAt).format(
                          "DD/MM/YYYY"
                        ) || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Notification Message
                      </Typography>
                      <Typography component="p">
                        <p
                          className="notfcn_msg"
                          dangerouslySetInnerHTML={{
                            __html: notificationData
                              ? notificationData?.message
                              : "",
                          }}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default NotificationDetails;
