import { END_POINTS } from "../constants/url";
import {
  Categories,
  CategoryByIdResponse,
  SubCategoriesType,
} from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllCategoriesResponse = {
  count: number;
  data: Categories[];
};

type GetTokenParams = {
  parentId?: string;
  type?: number;
  limit?: number;
  page?: number;
  query?: string;
};

type GetCategoryStatusResponse = {
  isActive: boolean;
};
type AddCategoryBody = {
  name: string;
  image?: string;
  type: number; //1 in case of budget
  parent?: string; // only in case of goals
};
type GetSubcategoriesResponse = {
  count: number;
  data: SubCategoriesType[];
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query<
      CommonResponseType & { data: GetAllCategoriesResponse },
      GetTokenParams
    >({
      query: ({ type, limit, page, query }) => ({
        url:
          END_POINTS.categories +
          "?type=" +
          type +
          "&page=" +
          page +
          "&size=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getGoalCategoriesList: builder.query<
      CommonResponseType & { data: GetAllCategoriesResponse },
      { type: number }
    >({
      query: ({ type }) => ({
        url: END_POINTS.categories + "?type=" + type,
        method: "GET",
      }),
    }),
    getGoalSubcategoriesList: builder.query<
      CommonResponseType & { data: GetSubcategoriesResponse },
      { parent: string | undefined }
    >({
      query: ({ parent }) => ({
        url: `${END_POINTS.categories}?parent=${parent}`,
        method: "GET",
      }),
    }),

    changeCategoryStatus: builder.mutation<
      CommonResponseType & { data: GetCategoryStatusResponse },
      { body: { isActive: boolean }; category_id: string }
    >({
      query: ({ category_id, body }) => ({
        url: `${END_POINTS.categories}/${category_id}/`,
        method: "PUT",
        body,
      }),
    }),
    addCategory: builder.mutation<
      CommonResponseType & { data: GetCategoryStatusResponse },
      { body: AddCategoryBody }
    >({
      query: ({ body }) => ({
        url: END_POINTS.categories,
        method: "POST",
        body,
      }),
    }),
    getCategoryById: builder.query<
      CommonResponseType & { data: CategoryByIdResponse },
      { category_id: string }
    >({
      query: ({ category_id }) => ({
        url: `${END_POINTS.categories}/${category_id}`,
        method: "GET",
      }),
    }),

    editCategoryById: builder.mutation<
      CommonResponseType & { data: Categories },
      { category_id: string; body: AddCategoryBody }
    >({
      query: ({ category_id, body }) => ({
        url: `${END_POINTS.categories}/${category_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteCategoryById: builder.query<
      CommonResponseType & { data: Categories },
      { category_id: string }
    >({
      query: ({ category_id }) => ({
        url: `${END_POINTS.categories}/${category_id}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useLazyGetAllCategoriesQuery,
  useLazyGetGoalCategoriesListQuery,
  useLazyGetGoalSubcategoriesListQuery,
  useAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useEditCategoryByIdMutation,
  useChangeCategoryStatusMutation,
  useLazyDeleteCategoryByIdQuery,
} = mainApi;
