import { END_POINTS } from "../constants/url";
import { GetSavingsLimit } from "../types/General";

import emptySplitApi from "../utils/rtk";

type GetSavingsLimitResponse = {
  data: {
    count: number;
    data: GetSavingsLimit[];
  };
  statusCode: number;
  message: string;
};

type EditSavingsLimitBody = {
  overallSavingsLimit?: {
    minimumGoalAmountLimit: number;
    maximumGoalAmountLimit: number;
    noOfBreakPoints: number;
  };
  monthlySavingsLimit?: {
    minimumGoalAmountLimit: number;
    maximumGoalAmountLimit: number;
    noOfBreakPoints: number;
  };
  transactionFee?: number;
  dateOfTransfer?: string;
  transferSavingsPoints?: number;
  minPointsForReward?: number;
  rewardAmount?: number;
  maxDaysLimitForWithdrawl?: number;
  isIos?: boolean;
  isAndroid?: boolean;
};

export const savingsLimitApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavingsLimit: builder.query<GetSavingsLimitResponse, {}>({
      query: () => ({
        url: END_POINTS.settings,
        method: "GET",
      }),
    }),

    editSavingsLimit: builder.mutation<
      GetSavingsLimitResponse,
      { id: string; body: EditSavingsLimitBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.settings}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    cancelSubscription: builder.query<
      GetSavingsLimitResponse,
      { fieldName: string }
    >({
      query: ({ fieldName }) => ({
        url: `${END_POINTS.cancelSubscription}?subscriptionId=${fieldName}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetSavingsLimitQuery,
  useEditSavingsLimitMutation,
  useLazyCancelSubscriptionQuery,
} = savingsLimitApi;
