import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useEffect, useState } from "react";
import { MainFaq } from "../../components";
import {
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
  useLazyGetFaqQuery,
} from "../../services/cms";
import { Faqs } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Permissions } from "../../types/User";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Faq = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [value, setValue] = useState<number>(0);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqsData, setFaqsData] = useState<Faqs[]>([]);
  const [getFaqData, { isLoading }] = useLazyGetFaqQuery();
  const [postFaq, postFaqData] = useAddFaqMutation();
  const [deleteFaq, deleteFaqData] = useLazyDeleteFaqQuery();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage FAQs"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const handleAddFaq = async () => {
    const body = {
      question: question,
      answer: answer,
      type: value === 0 ? 1 : value === 1 ? 2 : value === 2 ? 3 : 4,
      appkey: Date.now(),
    };

    try {
      const res = await postFaq({ body }).unwrap();
      if (res?.statusCode === 200) {
        getAllFaqsHandler();
        showToast("FAQ added successfully");
        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getAllFaqsHandler = async () => {
    try {
      const response = await getFaqData({
        type: value === 0 ? 1 : value === 1 ? 2 : value === 2 ? 3 : 4,
      }).unwrap();
      if (response?.statusCode === 200) {
        setFaqsData(response?.data?.data || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleDeleteFaq = async (id: string) => {
    try {
      const response = await deleteFaq({ faq_id: id }).unwrap();
      if (response?.statusCode === 200) {
        getAllFaqsHandler();
        showToast("Faq deleted successfully!");
      }
    } catch (error: any) {
      showError(error?.message || "");
    }
  };
  useEffect(() => {
    getAllFaqsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllFaqsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <MainContainer>
      <Loader
        isLoad={isLoading || postFaqData?.isLoading || deleteFaqData?.isLoading}
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Faqs</h1>
        </div>
        <div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Budget " {...a11yProps(0)} />
                <Tab label="Goals " {...a11yProps(1)} />
                <Tab label="Round Up " {...a11yProps(2)} />
                <Tab label="Savings " {...a11yProps(3)} />
              </Tabs>
              <MainFaq
                faqsData={faqsData}
                handleAddFaq={handleAddFaq}
                handleDelete={handleDeleteFaq}
                setQuestion={setQuestion}
                setAnswer={setAnswer}
                question={question}
                answer={answer}
                hidePermission={hidePermission}
                userData={userData}
              />
            </Box>
          </Card>
        </div>
      </div>
    </MainContainer>
  );
};

export default Faq;
