/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isString, useFormik } from "formik";
import { isValidInput } from "../../utils/validations";
import {
  useAddCategoryMutation,
  useChangeCategoryStatusMutation,
  useEditCategoryByIdMutation,
  useLazyDeleteCategoryByIdQuery,
  useLazyGetAllCategoriesQuery,
  useLazyGetCategoryByIdQuery,
} from "../../services/categories";
import {
  Loader,
  STORAGE_KEYS,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { Categories } from "../../types/General";
import { Pagination, WarnModal } from "../../components";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCategories = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [value, setValue] = useState<number>(state === "GOAL" ? 1 : 0);
  const [open, setOpen] = useState<boolean>(false);
  const [openWarnModal, setOpenWarnModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [categories, setCategories] = useState<Categories[]>([]);

  const [getCategoriesList, { isLoading }] = useLazyGetAllCategoriesQuery();
  const [categoryStatus, getCategoryStatusData] =
    useChangeCategoryStatusMutation();
  const [deleteCategoryById, GetDeleteCategoryData] =
    useLazyDeleteCategoryByIdQuery();
  const [addCategory, GetAddCategoryData] = useAddCategoryMutation();
  const [updateCategory, getUpdateCategoryData] = useEditCategoryByIdMutation();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Categories"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  const getCategories = async () => {
    try {
      const response = await getCategoriesList({
        type: value === 0 ? 1 : value === 1 ? 2 : 5,
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isActive: !state,
      appkey: Date.now(),
    };
    try {
      const response = await categoryStatus({
        category_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getCategories();
        if (response?.data?.isActive) {
          showToast("Category has been successfully enabled");
        } else {
          showToast("Category has been successfully disabled");
        }
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategoryById({ category_id: id }).unwrap();
      if (response?.statusCode === 200) {
        getCategories();
        showToast(response?.message || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId("");
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        name: formik.values.category,
        type: 2,
        appkey: Date.now(),
      };
      if (selectedId) {
        try {
          console.log("edit");
          const response = await updateCategory({
            category_id: `${selectedId}`,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "Category Updated Successfully");
            handleClose();
            setValue(1);
            getCategories();
          }
        } catch (error: any) {
          console.log(error);
        }
      } else {
        try {
          const response = await addCategory({
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            handleClose();
            setValue(1);
            getCategories();
          }
        } catch (error: any) {
          console.log(error);
        }
        formik.setSubmitting(false);
      }
    },
  });
  const handleClickOpen = (id: string) => {
    setOpen(true);
    setSelectedId(id);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCategories();
  }, [debouncedSearchTerm, page, value]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          getCategoryStatusData?.isLoading ||
          GetAddCategoryData?.isLoading ||
          GetDeleteCategoryData?.isLoading ||
          getUpdateCategoryData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Categories</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Budget Categories" {...a11yProps(0)} />
              <Tab label="Goal Categories" {...a11yProps(1)} />
              <Tab label="Marketplace Categories" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />

                <Box className="cards_header_right">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => {
                        navigate("/manage-categories/add", {
                          state: { tab: "BUDGET" },
                        });
                        removeFromStorage(STORAGE_KEYS?.categoryType);
                      }}
                    >
                      Add Category
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Category Name</TableCell>
                      <TableCell align="center"> Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {categories?.length ? (
                    categories?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              <figure className="user_img">
                                <img
                                  src={
                                    item?.image
                                      ? item?.image
                                      : "/static/images/user_placeholder.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{item?.name || ""}</TableCell>
                            <TableCell align="center">
                              <Switch
                                {...label}
                                checked={item?.isActive}
                                onClick={() =>
                                  updateStatus(item?._id, item?.isActive)
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Box className="table_actions">
                                {hidePermission?.isEdit ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      navigate(
                                        `/manage-categories/edit/${item?._id}`,
                                        { state: { tab: "BUDGET" } }
                                      );
                                      removeFromStorage(
                                        STORAGE_KEYS.categoryType
                                      );
                                    }}
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                ) : null}
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpenWarnModal(true);
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={10}>
                        No Categories Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Add Category
                    </Button>
                  ) : null}
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => {
                        navigate("/manage-categories/add", { state: "GOAL" });
                        setToStorage(STORAGE_KEYS?.categoryType, "GOAL");
                      }}
                    >
                      Add Sub-category
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Category Name</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {categories?.length ? (
                    categories?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>{item?.name || ""}</TableCell>
                            <TableCell align="center">
                              <Switch
                                {...label}
                                checked={item?.isActive}
                                onClick={() =>
                                  updateStatus(item?._id, item?.isActive)
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Box className="table_actions">
                                <IconButton
                                  disabled={!item?.isActive}
                                  onClick={() =>
                                    navigate(
                                      `/manage-categories/details/${item?._id}`,
                                      {
                                        state: { item },
                                      }
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {hidePermission?.isEdit ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      handleClickOpen(item?._id);
                                      formik.setFieldValue(
                                        "category",
                                        item?.name
                                      );
                                    }}
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                ) : null}
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpenWarnModal(true);
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        // sx={{ color: "white" }}
                      >
                        No Categories Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />

                <Box className="cards_header_right">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => {
                        navigate("/manage-categories/add", {
                          state: { tab: "MERCHANT" },
                        });
                        removeFromStorage(STORAGE_KEYS?.categoryType);
                      }}
                    >
                      Add Category
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Category Name</TableCell>
                      <TableCell align="center"> Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {categories?.length ? (
                    categories?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              <figure className="user_img">
                                <img
                                  src={
                                    item?.image
                                      ? item?.image
                                      : "/static/images/user_placeholder.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{item?.name || ""}</TableCell>
                            <TableCell align="center">
                              <Switch
                                {...label}
                                checked={item?.isActive}
                                onClick={() =>
                                  updateStatus(item?._id, item?.isActive)
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Box className="table_actions">
                                {hidePermission?.isEdit ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      navigate(
                                        `/manage-categories/edit/${item?._id}`,
                                        { state: { tab: "MERCHANT" } }
                                      );
                                      removeFromStorage(
                                        STORAGE_KEYS.categoryType
                                      );
                                    }}
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                ) : null}
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpenWarnModal(true);
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={10}>
                        No Categories Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Category</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="category"
                name="category"
                type="text"
                placeholder="Add Category"
                fullWidth
                inputProps={{ maxLength: 30 }}
                value={formik.values.category}
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.category && formik.errors.category}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Add
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Pagination
        module={categories}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        open={openWarnModal}
        setOpen={setOpenWarnModal}
        handleDelete={() => handleDelete(selectedId)}
        name="category"
      />
    </MainContainer>
  );
};

export default ManageCategories;
