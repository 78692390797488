// import React, { useCallback, useEffect, useState } from "react";
// import MainContainer from "../../layout/MainContainer";
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   Grid,
//   IconButton,
//   Switch,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";

// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {
//   useAddSubscriptionMutation,
//   useEditSubscriptionByIdMutation,
//   useLazyGetSubscriptionByIdQuery,
// } from "../../services/subscription";
// import { GetSubscriptionById } from "../../types/General";
// import { Loader, showError, showToast } from "../../constants";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import { isNumber, isString } from "../../utils/validations";
// import { log } from "console";
// import { Permissions } from "../../types/User";
// import useAuth from "../../hooks/useAuth";

// const AddSubscriptionPlan = () => {
//   const userData = useAuth();
//   const { id } = useParams();
//   const location = useLocation();
//   const { state } = location;
//   const navigate = useNavigate();
//   const [getSubscriptionById, getSubscriptionByIdData] =
//     useLazyGetSubscriptionByIdQuery();
//   const [updateSubscription, getUpdateSubscriptionData] =
//     useEditSubscriptionByIdMutation();
//   const [subscriptionData, setSubscriptionData] =
//     useState<GetSubscriptionById>();
//   const [addSubscription, { isLoading }] = useAddSubscriptionMutation();

//   const [enabledFeatures, setEnabledFeatures] = useState<string[]>([]);
//   const [hidePermission, setHidePermission] = useState<
//     Permissions | null | undefined
//   >(null);
//   console.log(enabledFeatures, "enabledFeatures");

//   const userPermissions = userData?.permissions?.length
//     ? JSON.parse(`${userData?.permissions}`)
//     : [];

//   const handleFeatureToggle = (position: number) => {
//     // Check if the feature is already enabled
//     const isEnabled = enabledFeatures.includes(position.toString());

//     // Update enabledFeatures based on toggle action
//     if (isEnabled) {
//       // Remove the feature if already enabled
//       setEnabledFeatures((prevFeatures) =>
//         prevFeatures.filter((feature) => feature !== position.toString())
//       );
//     } else {
//       // Add the feature if not enabled
//       setEnabledFeatures((prevFeatures) => [
//         ...prevFeatures,
//         position.toString(),
//       ]);
//     }
//   };

//   const getSubscriptionData = async (id: string) => {
//     try {
//       const response = await getSubscriptionById({
//         subscription_id: id,
//       }).unwrap();
//       if (response?.statusCode === 200) {
//         setSubscriptionData(response?.data);

//         setEnabledFeatures(response?.data?.features);
//       }
//     } catch (error: any) {
//       showError(error?.data?.message || "");
//     }
//   };
//   useEffect(() => {
//     if (id) {
//       getSubscriptionData(id);
//     }
//   }, []);

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       name: subscriptionData?.name || "",
//       validity: subscriptionData?.validity || "",
//       price: subscriptionData?.price || "",
//       description: subscriptionData?.description || "",
//       noOfAccounts: subscriptionData?.noOfAccounts || "",
//     },
//     validationSchema: Yup.object({
//       name: Yup.string()
//         .required("This field is required")
//         .min(3, "Minimum 3 characters are required")
//         .max(30, "Maximum 30 characters are allowed"),
//       validity: Yup.string().required("This field is required"),
//       price: Yup.string().required("This field is required"),
//       description: Yup.string()
//         .required("This field is required")
//         .min(3, "Minimum 3 characters are required"),
//       noOfAccounts: Yup.string().required("This field is required"),
//     }),
//     onSubmit: async () => {
//       formik.setSubmitting(true);

//       const body = {
//         name: formik.values.name,
//         validity: Number(formik.values.validity),
//         price: Number(formik.values.price),
//         description: formik.values.description,
//         noOfAccounts: Number(formik.values.noOfAccounts),
//         features: enabledFeatures,
//         appkey: Date.now(),
//       };
//       console.log(body);

//       if (id) {
//         console.log("edit");

//         try {
//           const response = await updateSubscription({
//             subscription_id: id,
//             body,
//           }).unwrap();
//           if (response?.statusCode === 200) {
//             showToast(response?.message || "");
//             navigate("/manage-subscription");
//           } else {
//             showError(response?.message || "");
//           }
//         } catch (error: any) {
//           showError(error?.data?.message || "");
//           console.log(error);
//         }
//       } else {
//         console.log("add");
//         try {
//           const response = await addSubscription({ body }).unwrap();
//           if (response?.statusCode === 200) {
//             showToast(response?.message || "");
//             formik.resetForm();
//             navigate("/manage-subscription");
//           } else {
//             showError(response?.message || "");
//           }
//         } catch (error: any) {
//           showError(error?.data?.message || "");
//           console.log(error);
//         }
//       }
//     },
//   });

//   const label = { inputProps: { "aria-label": "Switch demo" } };
//   const planFeatures = [
//     {
//       position: 1,
//       name: "Enhanced Budget Tools",
//       // isEnabled: enabledFeatures.charAt(0) === "1",
//     },
//     {
//       position: 2,
//       name: "Link Bank Accounts",
//       // isEnabled: enabledFeatures.charAt(0) === "2",
//     },
//     {
//       position: 3,
//       name: "Advanced Security",
//       // isEnabled: enabledFeatures.charAt(0) === "3",
//     },
//     {
//       position: 4,
//       name: "Track Spending Habits",
//       // isEnabled: enabledFeatures.charAt(0) === "4",
//     },
//     {
//       position: 5,
//       name: "Graphs and Charts",
//       // isEnabled: enabledFeatures.charAt(0) === "5",
//     },
//     {
//       position: 6,
//       name: "Savings jar to visually watch the progress",
//       // isEnabled: enabledFeatures.charAt(0) === "6",
//     },
//     {
//       position: 7,
//       name: "Recommendations and Analytics",
//       // isEnabled: enabledFeatures.charAt(0) === "7",
//     },
//     {
//       position: 8,
//       name: "Educational Tips",
//       // isEnabled: enabledFeatures.charAt(0) === "8",
//     },
//     {
//       position: 9,
//       name: "Savings Goals",
//       // isEnabled: enabledFeatures.charAt(0) === "9",
//     },
//     {
//       position: 10,
//       name: "Round-Up Savings to Achieve Goals",
//       // isEnabled: enabledFeatures.charAt(0) === "10",
//     },
//     {
//       position: 11,
//       name: "With No Ads",
//       // isEnabled: enabledFeatures.charAt(0) === "11",
//     },
//     {
//       position: 12,
//       name: "Notifications",
//       // isEnabled: enabledFeatures.charAt(0) === "12",
//     },
//     {
//       position: 13,
//       name: "Reward Points",
//       // isEnabled: enabledFeatures.charAt(0) === "13",
//     },
//     {
//       position: 14,
//       name: "Dive deep into budgets",
//       // isEnabled: enabledFeatures.charAt(0) === "14",
//     },
//     {
//       position: 15,
//       name: "Bill Reminders",
//       // isEnabled: enabledFeatures.charAt(0) === "15",
//     },
//     {
//       position: 16,
//       name: "Multiplier to Accelerate Goals",
//       // isEnabled: enabledFeatures.charAt(0) === "16",
//     },
//     {
//       position: 17,
//       name: "Manual ACH transfers between the external accounts",
//       // isEnabled: enabledFeatures.charAt(0) === "17",
//     },
//     {
//       position: 18,
//       name: "Auto ACH transfers between the external accounts",
//       // isEnabled: enabledFeatures.charAt(0) === "18",
//     },
//   ];
//   const checkPermission = () => {
//     const permission = userPermissions;
//     if (permission?.length) {
//       let idx = -1;
//       idx = permission?.findIndex(
//         (ele: Permissions) => ele?.label === "Manage Subscription Plans"
//       );
//       if (idx > -1) {
//         const manageAddEditPermission = permission[idx];
//         if (manageAddEditPermission && !manageAddEditPermission?.isEdit) {
//           navigate("/permission-denied", { replace: true });
//         } else {
//           setHidePermission(manageAddEditPermission);
//         }
//       } else {
//         navigate("/permission-denied", { replace: true });
//       }
//     }
//   };
//   useEffect(() => {
//     if (userData && userPermissions?.length) {
//       checkPermission();
//     }
//   }, [userData]);

//   return (
//     <MainContainer>
//       <Loader isLoad={getSubscriptionByIdData?.isLoading} />
//       <div className="main_loyout">
//         <div className="dashboard">
//           {id ? (
//             <h1 className="mn_hdng">Edit Plan</h1>
//           ) : (
//             <h1 className="mn_hdng">Add Plan</h1>
//           )}
//           <Button
//             className="btn btn_primary"
//             onClick={() => {
//               navigate("/manage-subscription");
//             }}
//           >
//             Back
//           </Button>
//         </div>
//         <Card className="cards">
//           <form onSubmit={formik.handleSubmit}>
//             <CardContent sx={{ p: 1 }}>
//               <Grid container spacing={2}>
//                 <Grid item lg={6} md={6} sm={6} xs={12}>
//                   <Typography className="custom_label">Plan Name</Typography>
//                   <TextField
//                     hiddenLabel
//                     type={"text"}
//                     name="name"
//                     variant="outlined"
//                     fullWidth
//                     placeholder="Plan Name"
//                     id="name"
//                     inputProps={{ maxLength: 30 }}
//                     className="text_field"
//                     onBlur={formik.handleBlur}
//                     value={formik.values.name}
//                     onChange={(val) => {
//                       if (
//                         val.target.value === " " ||
//                         val.target.value === "."
//                       ) {
//                       } else if (isString(val.target.value)) {
//                         formik.handleChange(val);
//                       }
//                     }}
//                     helperText={formik.touched.name && formik.errors.name}
//                   />
//                 </Grid>
//                 <Grid item lg={6} md={6} sm={6} xs={12}>
//                   <Typography className="custom_label">
//                     Plan Validity (months)
//                   </Typography>
//                   <TextField
//                     hiddenLabel
//                     type={"text"}
//                     name="validity"
//                     variant="outlined"
//                     fullWidth
//                     placeholder="Plan Validity"
//                     id="validity"
//                     className="text_field"
//                     onBlur={formik.handleBlur}
//                     value={formik.values.validity}
//                     onChange={(val) => {
//                       if (
//                         val.target.value === " " ||
//                         val.target.value === "."
//                       ) {
//                       } else if (isNumber(val.target.value)) {
//                         formik.handleChange(val);
//                       }
//                     }}
//                     helperText={
//                       formik.touched.validity && formik.errors.validity
//                     }
//                   />
//                 </Grid>
//                 <Grid item lg={6} md={6} sm={6} xs={12}>
//                   <Typography className="custom_label">Plan Price</Typography>
//                   <TextField
//                     hiddenLabel
//                     type={"text"}
//                     name="price"
//                     variant="outlined"
//                     fullWidth
//                     placeholder="Plan Price"
//                     id="price"
//                     className="text_field"
//                     inputProps={{ maxLength: 15 }}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.price}
//                     onChange={(event) => {
//                       const inputValue = event.target.value;
//                       // Validate input: allow digits, up to one decimal point, and ensure no more than two decimal places
//                       const regex = /^\d*\.?\d{0,2}$/;
//                       if (regex.test(inputValue)) {
//                         formik.handleChange(event);
//                       }
//                     }}
//                     helperText={formik.touched.price && formik.errors.price}
//                   />
//                 </Grid>
//                 <Grid item lg={6} md={6} sm={6} xs={12}>
//                   <Typography className="custom_label">
//                     Number of Bank Accounts
//                   </Typography>
//                   <TextField
//                     hiddenLabel
//                     type={"text"}
//                     name="noOfAccounts"
//                     variant="outlined"
//                     fullWidth
//                     placeholder="Number of Bank Accounts"
//                     id="noOfAccounts"
//                     className="text_field"
//                     onBlur={formik.handleBlur}
//                     value={formik.values.noOfAccounts}
//                     onChange={(val) => {
//                       if (
//                         val.target.value === " " ||
//                         val.target.value === "."
//                       ) {
//                       } else if (isNumber(val.target.value)) {
//                         formik.handleChange(val);
//                       }
//                     }}
//                     helperText={
//                       formik.touched.noOfAccounts && formik.errors.noOfAccounts
//                     }
//                   />
//                 </Grid>
//                 <Grid item lg={6} md={6} sm={6} xs={12}>
//                   <Typography className="custom_label">Description</Typography>
//                   <TextField
//                     className="text_field"
//                     hiddenLabel
//                     type="text"
//                     placeholder="Description"
//                     fullWidth
//                     multiline
//                     minRows={1.2}
//                     maxRows={4}
//                     name="description"
//                     variant="outlined"
//                     id="description"
//                     onChange={(val) => {
//                       if (
//                         val.target.value === " " ||
//                         val.target.value === "."
//                       ) {
//                       } else {
//                         formik.handleChange(val);
//                       }
//                     }}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.description}
//                     helperText={
//                       formik.touched.description && formik.errors.description
//                     }
//                   />
//                 </Grid>

//                 <Grid item xs={12}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Typography
//                       component="h2"
//                       sx={{
//                         fontSize: "20px",
//                         color: "#051140",
//                         fontWeight: 700,
//                       }}
//                     >
//                       Plan Features
//                     </Typography>
//                   </Box>
//                 </Grid>

//                 <Grid item xs={12}>
//                   <TableContainer
//                     className="table_container"
//                     sx={{ margin: 0 }}
//                   >
//                     <Box className="heading"></Box>
//                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell align="center">S.No</TableCell>
//                           <TableCell>Feature</TableCell>
//                           <TableCell> Status</TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {planFeatures.map((row, i) => (
//                           <TableRow key={i}>
//                             <TableCell align="center">
//                               {row?.position || ""}
//                             </TableCell>
//                             <TableCell>{row.name || ""}</TableCell>
//                             <TableCell>
//                               <Switch
//                                 {...label}
//                                 checked={enabledFeatures.includes(
//                                   row.position.toString()
//                                 )}
//                                 onChange={() =>
//                                   handleFeatureToggle(row.position)
//                                 }
//                                 size="small"
//                               />
//                             </TableCell>
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Grid>
//               </Grid>
//               <div className="form_btn">
//                 <Button size="large" type="submit" className="btn btn_primary">
//                   Save
//                 </Button>
//               </div>
//             </CardContent>
//           </form>
//         </Card>
//       </div>
//     </MainContainer>
//   );
// };

// export default AddSubscriptionPlan;
import React, { useCallback, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddSubscriptionMutation,
  useEditSubscriptionByIdMutation,
  useLazyGetSubscriptionByIdQuery,
} from "../../services/subscription";
import { GetSubscriptionById } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNumber, isString } from "../../utils/validations";
import { log } from "console";

const AddSubscriptionPlan = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [getSubscriptionById, getSubscriptionByIdData] =
    useLazyGetSubscriptionByIdQuery();
  const [updateSubscription, getUpdateSubscriptionData] =
    useEditSubscriptionByIdMutation();
  const [subscriptionData, setSubscriptionData] =
    useState<GetSubscriptionById>();
  const [addSubscription, { isLoading }] = useAddSubscriptionMutation();
  const [enabledFeatures, setEnabledFeatures] = useState<number[]>([]);
  console.log(enabledFeatures, "enabledFeatures");

  const handleFeatureToggle = useCallback(
    (position: number) => {
      if (enabledFeatures.includes(position)) {
        setEnabledFeatures(enabledFeatures.filter((item) => item !== position));
      } else {
        setEnabledFeatures([...enabledFeatures, position]);
      }
    },
    [enabledFeatures]
  );

  const getSubscriptionData = async (id: string) => {
    try {
      const response = await getSubscriptionById({
        subscription_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubscriptionData(response?.data);
        setEnabledFeatures(
          typeof response?.data?.features === "string"
            ? response?.data?.features.split(",").map(Number)
            : response?.data?.features || []
        );
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) {
      getSubscriptionData(id);
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: subscriptionData?.name || "",
      validity: subscriptionData?.validity || "",
      price: subscriptionData?.price || "",
      description: subscriptionData?.description || "",
      noOfAccounts: subscriptionData?.noOfAccounts || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required")
        .max(30, "Maximum 30 characters are allowed"),
      validity: Yup.string().required("This field is required"),
      price: Yup.string().required("This field is required"),
      description: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required"),
      noOfAccounts: Yup.string().required("This field is required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        name: formik.values.name,
        validity: Number(formik.values.validity),
        price: Number(formik.values.price),
        description: formik.values.description,
        noOfAccounts: Number(formik.values.noOfAccounts),
        features: enabledFeatures,
        appkey: Date.now(),
      };
      console.log(body, "body");

      if (id) {
        try {
          const response = await updateSubscription({
            subscription_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-subscription");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubscription({ body }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            formik.resetForm();
            navigate("/manage-subscription");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const planFeatures = [
    {
      position: 1,
      name: "Enhanced Budget Tools",
      isEnabled: false,
    },
    {
      position: 2,
      name: "Link Bank Accounts",
      isEnabled: false,
    },
    {
      position: 3,
      name: "Advanced Security",
      isEnabled: false,
    },
    {
      position: 4,
      name: "Track Spending Habbits",
      isEnabled: false,
    },
    {
      position: 5,
      name: "Graphs and Charts",
      isEnabled: false,
    },
    {
      position: 6,
      name: "Savings jar to visually watch the progress",
      isEnabled: false,
    },
    {
      position: 7,
      name: "Recommendations and Analytics",
      isEnabled: false,
    },
    {
      position: 8,
      name: "Educational Tips",
      isEnabled: false,
    },
    {
      position: 9,
      name: "Savings Goals",
      isEnabled: false,
    },
    {
      position: 10,
      name: "Round-Up Savings to Achieve Goals",
      isEnabled: false,
    },
    {
      position: 11,
      name: "With No Ads",
      isEnabled: false,
    },
    {
      position: 12,
      name: "Notifications",
      isEnabled: false,
    },
    {
      position: 13,
      name: "Reward Points",
      isEnabled: false,
    },
    {
      position: 14,
      name: "Dive deep into budgets",
      isEnabled: false,
    },
    {
      position: 15,
      name: "Bill Reminders",
      isEnabled: false,
    },
    {
      position: 16,
      name: "Multiplier to Accelerate Goals",
      isEnabled: false,
    },
    {
      position: 17,
      name: "Manual ACH transfers between the external accounts",
      isEnabled: false,
    },
    {
      position: 18,
      name: "Auto ACH transfers between the external accounts",
      isEnabled: false,
    },
  ];
  return (
    <MainContainer>
      <Loader isLoad={getSubscriptionByIdData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          {id ? (
            <h1 className="mn_hdng">Edit Plan</h1>
          ) : (
            <h1 className="mn_hdng">Add Plan</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Name"
                    id="name"
                    inputProps={{ maxLength: 30 }}
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Plan Validity (months)
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="validity"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Validity"
                    id="validity"
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={formik.values.validity}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.validity && formik.errors.validity
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Price"
                    id="price"
                    className="text_field"
                    inputProps={{ maxLength: 15 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      // Validate input: allow digits, up to one decimal point, and ensure no more than two decimal places
                      const regex = /^\d*\.?\d{0,2}$/;
                      if (regex.test(inputValue)) {
                        formik.handleChange(event);
                      }
                    }}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Number of Bank Accounts
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="noOfAccounts"
                    variant="outlined"
                    fullWidth
                    placeholder="Number of Bank Accounts"
                    id="noOfAccounts"
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={formik.values.noOfAccounts}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.noOfAccounts && formik.errors.noOfAccounts
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    placeholder="Description"
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="description"
                    variant="outlined"
                    id="description"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                      Plan Features
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>Feature</TableCell>
                          <TableCell> Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {row?.position || ""}
                            </TableCell>
                            <TableCell>{row.name || ""}</TableCell>
                            <TableCell>
                              <Switch
                                {...label}
                                checked={enabledFeatures.includes(row.position)}
                                onChange={() =>
                                  handleFeatureToggle(row.position)
                                }
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscriptionPlan;
