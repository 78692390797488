import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import { useLazyGetUserByIdQuery } from "../../../services/customers";
import { AllUser } from "../../../types/General";
import { Loader, showError } from "../../../constants";
import useAuth from "../../../hooks/useAuth";
import { Permissions } from "../../../types/User";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<AllUser>();
  const [getUserById, { isLoading }] = useLazyGetUserByIdQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const userPermissions = user?.permissions?.length
    ? JSON.parse(`${user?.permissions}`)
    : [];

  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        user_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Customers"
      );
      if (idx > -1) {
        const manageViewPermission = permission[idx];
        if (manageViewPermission && !manageViewPermission?.isView) {
          navigate("/permission-denied", { replace: true });
        } else {
          setHidePermission(manageViewPermission);
        }
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };
  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      getUserDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">
                        {` ${userData?.firstName || "-"}                        
                          ${userData?.lastName || ""}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={3} sm={4} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        {" "}
                        {userData?.countryCode
                          ? (userData?.countryCode.includes("+") ? "" : "+") +
                            userData?.countryCode
                          : null}{" "}
                        {userData?.phone || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {userData?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">
                        {userData?.isBlocked ? "Blocked" : "Active" || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Plan Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Plan Name</Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Type</Typography>
                          <Typography component="p">1 Month</Typography>
                        </Box>
                      </Grid> */}
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Plan Price</Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default UserDetails;
