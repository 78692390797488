import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import Settings from "./settings";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageBanner from "./manageBanner";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";

import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import AddBanner from "./manageBanner/add";
import ManageSubscription from "./manageSubscription";
import AddSubscriptionPlan from "./manageSubscription/add";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
import ManageEmail from "./manageEmailTemplate";
import SetPointsCriteria from "./setPointsCriteria";
import ManageRoundUp from "./manageRoundUp";
import ManageSavingsLimit from "./manageSavingsLimit";
import PermissionDenied from "./noPermissions";
import NotificationDetails from "./manageNotification/details";
import FeedbackUsers from "./feedback";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageBanner,
  Settings,
  ManageNotifications,
  AddNotification,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  AddBanner,
  ManageSubscription,
  AddSubscriptionPlan,
  SubCategories,
  ChangePassword,
  ManageEmail,
  FeedbackUsers,
  SetPointsCriteria,
  ManageRoundUp,
  ManageSavingsLimit,
  PermissionDenied,
  NotificationDetails,
};
