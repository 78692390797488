import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import { Pie } from "react-chartjs-2";
import { Loader, showError } from "../../constants";
import { useLazyGetDashboardQuery } from "../../services/dashboard";
import { DashboardData } from "../../types/General";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);

const Analytics = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [userFilter, setUserFilter] = useState<string>("MONTH");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [revenueFilter, setRevenueFilter] = useState<string>("MONTH");
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [getDashBoardData, { isLoading }] = useLazyGetDashboardQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Reports and Analytics"
      );

      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleRevenueChange = (event: SelectChangeEvent) => {
    setRevenueFilter(event.target.value as string);
  };

  const graphCustomerReportData = {
    labels: userNames,
    datasets: [
      {
        label: "Customers ",
        data: userValues,
        borderColor: "#72cedd",
        backgroundColor: "#72cedd",
      },
    ],
  };
  // const graphRevenueReportsData = {
  //   labels: dashboardData?.revenue?.map((item) => item?.name),
  //   datasets: [
  //     {
  //       label: "Revenue",
  //       data: dashboardData?.revenue?.map((item) => item?.total),
  //       borderColor: "#72cedd",
  //       backgroundColor: "#72cedd",
  //     },
  //   ],
  // };

  const getDashBoard = async () => {
    try {
      const response = await getDashBoardData({
        type: userFilter,
        type2: revenueFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setDashboardData(response?.data);
        if (userFilter === "YEAR") {
          setUserNames(Object.keys(response?.data?.result));
          setUserValues(Object.values(response?.data?.result));
        } else {
          setUserNames(
            response?.data?.result?.map((item: { name: any }) => item?.name)
          );
          setUserValues(
            response?.data?.result?.map((item: { count: any }) => item?.count)
          );
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getDashBoard();
  }, [userFilter, revenueFilter]);

  const piechartData = {
    labels: ["SaverLite", "SaverPro", "SaverElite"],
    datasets: [
      {
        label: "",
        data: [50, 50, 30],
        backgroundColor: ["#72cedd", "#1c6975", "#051140"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Customers
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleUserChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="DAILY">Daily</MenuItem>
                    <MenuItem value="WEEK">Weekly</MenuItem>
                    <MenuItem value="MONTH">Monthly</MenuItem>
                    <MenuItem value="YEAR">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphCustomerReportData} />
            </div>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Revenue
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={revenueFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleRevenueChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="DAILY">Daily</MenuItem>
                    <MenuItem value="WEEK">Weekly</MenuItem>
                    <MenuItem value="MONTH">Monthly</MenuItem>
                    <MenuItem value="YEAR">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueReportsData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">Popular Plans</h2>
              <Pie
                data={piechartData}
                style={{ height: "300px", width: "300px" }}
              />
            </div>
          </Grid> */}
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
