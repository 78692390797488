import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  useEditSavingsLimitMutation,
  useLazyGetSavingsLimitQuery,
} from "../../services/savingsLimit";
import * as Yup from "yup";
import { GetSavingsLimit } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Permissions } from "../../types/User";
import { Loader, showError, showToast } from "../../constants";
import { useFormik } from "formik";
import { isNumber } from "../../utils/validations";

const SetPointsCriteria = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [getSavingsLimit, { isLoading }] = useLazyGetSavingsLimitQuery();
  const [updateSavingsLimit, UpdateSavingsLimitData] =
    useEditSavingsLimitMutation();
  const [savingsLimitData, setSavingsLimitData] = useState<GetSavingsLimit>();
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Set Points Criteria"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };
  const savingsLimit = async () => {
    try {
      const response = await getSavingsLimit({}).unwrap();
      if (response?.statusCode === 200) {
        setSavingsLimitData(response?.data?.data[0]);
        setSelectedId(response?.data?.data[0]?._id);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      transferSavingsPoints: savingsLimitData?.transferSavingsPoints || "",
      minRewardPoints: savingsLimitData?.minPointsForReward || "",
      rewardAmount: savingsLimitData?.rewardAmount || "",
      maxWithdrawlLimit: savingsLimitData?.maxDaysLimitForWithdrawl || "",
    },
    validationSchema: Yup.object({
      transferSavingsPoints: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),

      minRewardPoints: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 8 digits are allowed"),
      rewardAmount: Yup.string()
        .required("This field is required")
        .max(8, "Maximum 2 digits are allowed"),
      maxWithdrawlLimit: Yup.string()
        .required("This field is required")
        .max(3, "Maximum 3 digits are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let body = {
        transferSavingsPoints: Number(formik.values.transferSavingsPoints),
        minPointsForReward: Number(formik.values.minRewardPoints),
        rewardAmount: Number(formik.values.rewardAmount),
        maxDaysLimitForWithdrawl: Number(formik.values.maxWithdrawlLimit),
        appkey: Date.now(),
      };
      console.log(body, "body");
      if (selectedId) {
        try {
          const response = await updateSavingsLimit({
            id: selectedId,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Points Criteria Saved Succesfully");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(() => {
    savingsLimit();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || UpdateSavingsLimitData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Set Points Criteria</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ paddingBottom: "10px" }}>
                  <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Transfer Saving Points
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="transferSavingsPoints"
                        variant="outlined"
                        fullWidth
                        placeholder="Transfer Saving Points"
                        id="transferSavingsPoints"
                        inputProps={{ maxLength: 8 }}
                        className="text_field"
                        value={formik.values.transferSavingsPoints}
                        onBlur={formik.handleBlur}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.transferSavingsPoints &&
                          formik.errors.transferSavingsPoints
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Minimum points for reward
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="minRewardPoints"
                        variant="outlined"
                        fullWidth
                        placeholder="Minimum Points"
                        id="minRewardPoints"
                        inputProps={{ maxLength: 8 }}
                        className="text_field"
                        value={formik.values.minRewardPoints}
                        onBlur={formik.handleBlur}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.minRewardPoints &&
                          formik.errors.minRewardPoints
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Reward Amount
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="rewardAmount"
                        variant="outlined"
                        fullWidth
                        placeholder="Reward Amount"
                        id="rewardAmount"
                        inputProps={{ maxLength: 2 }}
                        className="text_field"
                        value={formik.values.rewardAmount}
                        onBlur={formik.handleBlur}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.rewardAmount &&
                          formik.errors.rewardAmount
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Maximum days limit for withdrawl
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="maxWithdrawlLimit"
                        variant="outlined"
                        fullWidth
                        placeholder="Maximum Days"
                        id="maxWithdrawlLimit"
                        inputProps={{ maxLength: 3 }}
                        className="text_field"
                        value={formik.values.maxWithdrawlLimit}
                        onBlur={formik.handleBlur}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.maxWithdrawlLimit &&
                          formik.errors.maxWithdrawlLimit
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="form_btn">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default SetPointsCriteria;
