
import './Users.scss';
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FeedbackIcon from "@mui/icons-material/Feedback";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  Rating,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { AllUser } from "../../types/General";
import {
  useChangeUserStatusMutation,
  useLazyDeleteUserIdQuery,
  useLazyGetAllUsersQuery,
  useLazyGetUserCSVQuery,
  useLazyUsersDeletedQuery,
} from "../../services/customers";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import moment from "moment";

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  feedback: string;
  userName: string;
}
// Feedback Dialog Component
const FeedbackDialog:React.FC<FeedbackDialogProps> = ({ open, onClose, feedback, userName }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="feedback-dialog"
    >
      <DialogTitle className="dialog-title">
        <Typography variant="h6" component="span">
          Customer Feedback
        </Typography>
        <Typography variant="subtitle1" component="span" className="user-name">
          {userName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className="feedback-content">
          {feedback || "No feedback provided"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FeedbackUsers = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<AllUser[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [feedbackFilter, setFeedbackFilter] = useState<'all' | 'withFeedback' | 'withoutFeedback'>('all');
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  
  // New state for feedback dialog
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  
  const [getAllUsersList, { isLoading: isLoadingAllUsers }] = useLazyGetAllUsersQuery();
  const [getDeletedUsers, { isLoading: isLoadingDeletedUsers }] = useLazyUsersDeletedQuery();
  const [getUserCsvMethod] = useLazyGetUserCSVQuery();
  const [deleteUserById, GetDeleteUserData] = useLazyDeleteUserIdQuery();
  const [userStatus, GetUserStatusData] = useChangeUserStatusMutation();
  
  const [hidePermission, setHidePermission] = useState<Permissions | null | undefined>(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Customers"
      );

      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // Handle feedback cell click
  const handleFeedbackClick = (feedback: string, userName: string) => {
    setSelectedFeedback(feedback);
    setSelectedUserName(userName);
    setFeedbackDialogOpen(true);
  };

  const filterUsers = (users: AllUser[]) => {
    switch (feedbackFilter) {
      case 'withFeedback':
        return users.filter((user) => user?.feedback && user.feedback.trim() !== "");
      case 'withoutFeedback':
        return users;
      default:
        return users;
    }
  };

  const getUsers = async () => {
    try {
      setIsFilterLoading(true);
      if (feedbackFilter === 'withoutFeedback') {
        const response = await getDeletedUsers({
          page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();

        if (response?.statusCode === 200) {
          const deletedUsersData = response?.data?.data || [];
          setFilteredUsers(deletedUsersData);
          setTotalCount(response?.data?.count || 0);
        } else {
          setFilteredUsers([]);
          setTotalCount(0);
        }
      } else {
        const response = await getAllUsersList({
          page: feedbackFilter === 'all' ? page : 1,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        
        if (response?.statusCode === 200) {
          const allUsersData = response?.data?.data || [];
          setAllUsers(allUsersData);
          
          if (feedbackFilter !== 'all') {
            const allPagesResponse = await getAllUsersList({
              page: 1,
              limit: response?.data?.count || 1000,
              query: debouncedSearchTerm.trim(),
            }).unwrap();
            
            if (allPagesResponse?.statusCode === 200) {
              const filteredUsers = filterUsers(allPagesResponse?.data?.data || []);
              setFilteredUsers(filteredUsers);
              setTotalCount(filteredUsers.length);
            }
          } else {
            setFilteredUsers(allUsersData);
            setTotalCount(response?.data?.count);
          }
        } else {
          setAllUsers([]);
          setFilteredUsers([]);
          setTotalCount(0);
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
      setIsFilterLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUserById({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.fileUrl || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const handleFeedbackFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: 'all' | 'withFeedback' | 'withoutFeedback'
  ) => {
    if (newFilter !== null) {
      setFeedbackFilter(newFilter);
      setPage(1);
    }
  };

  useEffect(() => {
    getUsers();
  }, [debouncedSearchTerm, page, feedbackFilter]);

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoadingAllUsers ||
          isLoadingDeletedUsers ||
          GetUserStatusData?.isLoading ||
          GetDeleteUserData?.isLoading ||
          isFilterLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Customers</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar
                placeholder="Search by Name"
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <ToggleButtonGroup
                value={feedbackFilter}
                exclusive
                onChange={handleFeedbackFilterChange}
                className="view-toggle"
              >
                <ToggleButton value="all" className="toggle-btn">
                  All Users
                </ToggleButton>
                <ToggleButton value="withFeedback" className="toggle-btn">
                  <FeedbackIcon sx={{ mr: 1 }} />
                  With Feedback
                </ToggleButton>
                <ToggleButton value="withoutFeedback" className="toggle-btn">
                  <DeleteIcon sx={{ mr: 1 }} />
                  Deleted Accounts
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone No.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Rating</TableCell>
                  <TableCell>Feedback</TableCell>
                </TableRow>
              </TableHead>
              {filteredUsers?.length ? (
                <TableBody>
                  {filteredUsers.map((item, i) => (
                    <TableRow key={item?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        {`${item?.firstName} ${item?.lastName}` || ""}
                      </TableCell>
                      <TableCell>
                        {item?.countryCode
                          ? (item?.countryCode.includes("+") ? "" : "+") +
                            item?.countryCode
                          : null}{" "}
                        {item?.phone || "-"}
                      </TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        {moment(item?.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <span className={`status ${item?.isDeleted ? 'deleted' : 'active'}`}>
                          {item?.isDeleted ? 'Deleted' : 'Active'}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <Rating
                          value={item?.rating || 0}
                          readOnly
                          size="small"
                          precision={0.5}
                        />
                      </TableCell>
                      <TableCell 
                        className="feedback-cell"
                        onClick={() => handleFeedbackClick(
                          item?.feedback,
                          `${item?.firstName} ${item?.lastName}`
                        )}
                      >
                        {item?.feedback 
                          ? `${item?.feedback.slice(0, 50)}${item?.feedback.length > 50 ? '...' : ''}`
                          : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={9}
                      sx={{ color: "#051140" }}
                    >
                      No Customers Found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={filteredUsers}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      
      {/* Feedback Dialog */}
      <FeedbackDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        feedback={selectedFeedback}
        userName={selectedUserName}
      />
      
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="customer"
      />
    </MainContainer>
  );
};

export default FeedbackUsers;