import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import {
  useChangeBannerStatusMutation,
  useLazyDeleteBannerQuery,
  useLazyGetBannersQuery,
} from "../../services/banner";
import { BannerResponse } from "../../types/General";
import { Pagination, WarnModal } from "../../components";
import { Loader, showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageBanner = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [bannerRespose, setBannerRespose] = useState<BannerResponse[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [getBanner, { isLoading }] = useLazyGetBannersQuery();
  const [deleteBannerById, GetDeleteBannerData] = useLazyDeleteBannerQuery();
  const [bannerStatus, GetBannerStatusData] = useChangeBannerStatusMutation();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Marketplace"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllBanner = async () => {
    try {
      const response = await getBanner({
        limit: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setBannerRespose(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setBannerRespose([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, state: boolean) => {
    const body = {
      isActive: !state,
      appkey: Date.now(),
    };

    try {
      const response = await bannerStatus({
        banner_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getAllBanner();
        if (response?.data?.isActive) {
          showToast("Banner has been successfully enabled");
        } else {
          showToast("Banner has been successfully disabled");
        }
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteBannerById({ banner_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getAllBanner();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllBanner();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          GetBannerStatusData?.isLoading ||
          GetDeleteBannerData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Banner</h1>
        </div>
        <Card className="cards">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            /> */}
            <Box className="cards_header_right">
              {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-banner/add")}
                >
                  Add Banner
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Banner Image</TableCell>
                  <TableCell align="center">Banner Link</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {bannerRespose?.length ? (
                bannerRespose?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell align="center">
                          <figure className="bnr_img">
                            <img
                              src={
                                row?.image
                                  ? row?.image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell align="center">
                          <a
                            href={row?.link || ""}
                            target="_blank"
                            rel="noreferrer"
                            className="banner_link"
                          >
                            {row?.link}
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          {row?.categoryId?.name || ""}
                        </TableCell>
                        {/* <TableCell align="center">20/09/2023</TableCell>
                  <TableCell align="center">30/09/2023</TableCell> */}
                        <TableCell align="center">
                          <Switch
                            {...label}
                            checked={row?.isActive}
                            onClick={() =>
                              updateStatus(row?._id, row?.isActive)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isEdit ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-banner/edit/${row?._id}`,
                                      {
                                        state: { type: "edit" },
                                      }
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={6}>
                    No Banners Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="banner"
      />
      <Pagination
        module={bannerRespose}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageBanner;
