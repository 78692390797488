import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Permissions } from "../../types/User";
import { NotificationResponse } from "../../types/General";
import {
  useLazyDeleteNotificationQuery,
  useLazyGetNotificationsQuery,
} from "../../services/notification";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";
import moment from "moment";

const ManageNotifications = () => {
  const userData = useAuth();
  const navigate = useNavigate();

  const [allNotifications, setAllNotifications] = useState<
    NotificationResponse[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getAllNotificationsList, { isLoading }] =
    useLazyGetNotificationsQuery();
  const [deleteNotificationById, GetDeleteNotificationData] =
    useLazyDeleteNotificationQuery();

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Notifications"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getNotifications = async () => {
    try {
      const response = await getAllNotificationsList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllNotifications(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllNotifications([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteNotificationById({
        notification_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getNotifications();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getNotifications();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }

  return (
    <MainContainer>
      <Loader isLoad={isLoading || GetDeleteNotificationData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-notifications/add")}
                >
                  Add Notification
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {allNotifications?.length ? (
                allNotifications?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>{item?.title || ""}</TableCell>
                        <TableCell>
                          <p
                            className="notfcn_msg"
                            dangerouslySetInnerHTML={{
                              __html: item ? item?.message : "",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {moment(item?.createdAt).format("DD/MM/YYYY") || ""}
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => {
                                navigate(
                                  `/manage-notifications/details/${item?._id}`
                                );
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>

                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Notifications Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={allNotifications}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="notification"
      />
    </MainContainer>
  );
};

export default ManageNotifications;
