import { END_POINTS } from "../constants/url";
import { BannerResponse, Faqs } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetBannersResponse = {
  data: {
    count: number;
    data: BannerResponse[];
  };
  statusCode: number;
  message: string;
};

type PostBannerBody = {
  link: string;
  image: string;
};
type GetBannersResponseById = {
  data: {
    createdAt: string;
    image: string;
    isActive: boolean;
    isDeleted: false;
    link: string;
    _id: string;
    categoryId: {
      name: string;
      _id: string;
    };
  };
  message: string;
  statusCode: number;
};

type GetBannerStatusResponse = {
  message: string;
  statusCode: number;
  data: {
    isActive: boolean;
  };
};
export const bannerApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBanners: builder.query<
      GetBannersResponse,
      { limit: number; page: number; query: string }
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.banner +
          "?page=" +
          page +
          "&size=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getBannerById: builder.query<any, { banner_id: string }>({
      query: ({ banner_id }) => ({
        url: `${END_POINTS.banner}/${banner_id}`,
        method: "GET",
      }),
    }),
    addBanner: builder.mutation<GetBannersResponse, { body: PostBannerBody }>({
      query: ({ body }) => ({
        url: `${END_POINTS.banner}`,
        method: "POST",
        body,
      }),
    }),
    changeBannerStatus: builder.mutation<
      GetBannerStatusResponse,
      { body: { isActive: boolean }; banner_id: string }
    >({
      query: ({ banner_id, body }) => ({
        url: `${END_POINTS.banner}/${banner_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateBanner: builder.mutation<
      GetBannersResponse,
      { body: PostBannerBody; banner_id: string }
    >({
      query: ({ body, banner_id }) => ({
        url: `${END_POINTS.banner}/${banner_id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteBanner: builder.query<GetBannersResponse, { banner_id: string }>({
      query: ({ banner_id }) => ({
        url: `${END_POINTS.banner}/${banner_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetBannersQuery,
  useLazyGetBannerByIdQuery,
  useChangeBannerStatusMutation,
  useLazyDeleteBannerQuery,
  useAddBannerMutation,
  useUpdateBannerMutation,
} = bannerApi;
