import { END_POINTS } from "../constants/url";
import {
  GetSavingsLimit,
  GetSubscriptionById,
  Subscriptions,
} from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetSavingsLimitResponse = {
  data: {
    count: number;
    data: GetSavingsLimit[];
  };
  statusCode: number;
  message: string;
};
type EditSavingsLimitBody = {
  isIos?: boolean;
  isAndroid?: boolean;
  isDebitAndroid?: boolean;
  isDebitIos?: boolean;
  overallSavingsLimit?: {
    minimumGoalAmountLimit: number;
    maximumGoalAmountLimit: number;
    noOfBreakPoints: number;
  };
  monthlySavingsLimit?: {
    minimumGoalAmountLimit: number;
    maximumGoalAmountLimit: number;
    noOfBreakPoints: number;
  };
  transactionFee?: number;
  dateOfTransfer?: string;
  transferSavingsPoints?: number;
  minPointsForReward?: number;
  rewardAmount?: number;
  maxDaysLimitForWithdrawl?: number;
};

type GetAllSubscriptionsResponse = {
  count: number;
  data: Subscriptions[];
};

type GetTokenParams = {
  limit: number;
  page: number;
  query: string;
};

type GetSubscriptionStatusResponse = {
  isActive: boolean;
};
type SubscriptionBody = {
  name: string;
  validity: number;
  price: number;
  description: string;
  noOfAccounts: number;
  features: number[];
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<
      CommonResponseType & { data: GetAllSubscriptionsResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.subscription +
          "?page=" +
          page +
          "&size=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    changeSubscriptionStatus: builder.mutation<
      CommonResponseType & { data: GetSubscriptionStatusResponse },
      { body: { isActive: boolean }; subscription_id: string }
    >({
      query: ({ subscription_id, body }) => ({
        url: `${END_POINTS.subscription}/${subscription_id}/`,
        method: "PUT",
        body,
      }),
    }),
    addSubscription: builder.mutation<
      CommonResponseType & { data: GetSubscriptionStatusResponse },
      { body: SubscriptionBody }
    >({
      query: ({ body }) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
    }),
    getSubscriptionById: builder.query<
      CommonResponseType & { data: GetSubscriptionById },
      { subscription_id: string }
    >({
      query: ({ subscription_id }) => ({
        url: `${END_POINTS.subscription}/${subscription_id}`,
        method: "GET",
      }),
    }),

    editSubscriptionById: builder.mutation<
      CommonResponseType & { data: Subscriptions },
      { subscription_id: string; body: SubscriptionBody }
    >({
      query: ({ subscription_id, body }) => ({
        url: `${END_POINTS.subscription}/${subscription_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteSubscriptionById: builder.query<
      CommonResponseType & { data: Subscriptions },
      { subscription_id: string }
    >({
      query: ({ subscription_id }) => ({
        url: `${END_POINTS.subscription}/${subscription_id}`,
        method: "DELETE",
      }),
    }),

    getSubscriptionSettings: builder.query<GetSavingsLimitResponse, {}>({
      query: () => ({
        url: END_POINTS.settings,
        method: "GET",
      }),
    }),

    editSubscriptionSettings: builder.mutation<
      GetSavingsLimitResponse,
      { id: string; body: EditSavingsLimitBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.settings}/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetAllSubscriptionsQuery,
  useLazyGetSubscriptionByIdQuery,
  useLazyDeleteSubscriptionByIdQuery,
  useAddSubscriptionMutation,
  useChangeSubscriptionStatusMutation,
  useEditSubscriptionByIdMutation,
  useLazyGetSubscriptionSettingsQuery,
  useEditSubscriptionSettingsMutation,
} = mainApi;
